import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Database,
  Cloud,
  Settings,
  Server,
  Users,
  Laptop,
  Lock,
  LineChart,
  ArrowRight,
  CheckCircle,
  Box,
  Cpu,
  Network,
} from "lucide-react";

const SoftwareSolutionsHero = () => {
  const [activeSegment, setActiveSegment] = useState("enterprise");

  const segments = [
    {
      id: "enterprise",
      icon: Database,
      title: "Enterprise Software",
      description: "Maßgeschneiderte Unternehmenslösungen",
      features: [
        "Prozessautomatisierung",
        "Datenintegration",
        "Workflow Management",
      ],
      techStack: ["Java", "Spring Boot", "PostgreSQL"],
    },
    {
      id: "cloud",
      icon: Cloud,
      title: "Cloud Lösungen",
      description: "Skalierbare Cloud-native Anwendungen",
      features: [
        "Microservices",
        "Container Orchestrierung",
        "Cloud Deployment",
      ],
      techStack: ["Docker", "Kubernetes", "AWS/Azure"],
    },
    {
      id: "integration",
      icon: Settings,
      title: "System Integration",
      description: "Nahtlose Verbindung bestehender Systeme",
      features: [
        "API Development",
        "Legacy Integration",
        "Echtzeit-Synchronisation",
      ],
      techStack: ["REST APIs", "GraphQL", "Apache Kafka"],
    },
  ];

  const solutions = [
    {
      icon: Server,
      title: "Backend Systeme",
      description: "Hochleistungsfähige Server-Architekturen",
    },
    {
      icon: Users,
      title: "Business Apps",
      description: "Intuitive Geschäftsanwendungen",
    },
    {
      icon: Lock,
      title: "Sichere Systeme",
      description: "Enterprise-Grade Sicherheit",
    },
    {
      icon: LineChart,
      title: "Datenanalyse",
      description: "Business Intelligence Lösungen",
    },
  ];

  return (
    <div className="min-h-screen pt-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-16">
        {/* Hero Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full mb-6">
            <Cpu className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
            <span className="text-blue-600 dark:text-blue-400 font-medium">
              Custom Software Solutions
            </span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">
            Enterprise Software
            <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {" "}
              Development
            </span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Entwickeln Sie Ihre digitale Zukunft mit maßgeschneiderter Software,
            die perfekt auf Ihre Unternehmensprozesse abgestimmt ist
          </p>
        </div>

        {/* Solutions Grid with Animation */}
        <div className="grid lg:grid-cols-3 gap-8 mb-16">
          {segments.map((segment) => (
            <motion.button
              key={segment.id}
              onClick={() => setActiveSegment(segment.id)}
              whileHover={{ scale: 1.02 }}
              className={`
                p-6 rounded-2xl transition-all duration-300
                ${
                  activeSegment === segment.id
                    ? "bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg"
                    : "bg-white dark:bg-gray-800 hover:shadow-lg"
                }
              `}
            >
              <div className="flex flex-col items-center text-center">
                {React.createElement(segment.icon, {
                  className: `w-12 h-12 mb-4 ${
                    activeSegment === segment.id
                      ? "text-white"
                      : "text-blue-600 dark:text-blue-400"
                  }`,
                })}
                <h3
                  className={`text-xl font-semibold mb-2 ${
                    activeSegment === segment.id
                      ? "text-white"
                      : "text-gray-900 dark:text-white"
                  }`}
                >
                  {segment.title}
                </h3>
                <p
                  className={`mb-4 ${
                    activeSegment === segment.id
                      ? "text-blue-100"
                      : "text-gray-600 dark:text-gray-300"
                  }`}
                >
                  {segment.description}
                </p>
                <div className="space-y-2">
                  {segment.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center justify-center">
                      <CheckCircle className="w-4 h-4 mr-2" />
                      <span className="text-sm">{feature}</span>
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex flex-wrap justify-center gap-2">
                  {segment.techStack.map((tech, idx) => (
                    <span
                      key={idx}
                      className={`px-3 py-1 rounded-full text-sm ${
                        activeSegment === segment.id
                          ? "bg-white/20 text-white"
                          : "bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400"
                      }`}
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </motion.button>
          ))}
        </div>

        {/* Capabilities Grid */}
        <div className="grid md:grid-cols-4 gap-6 mb-16">
          {solutions.map((solution, idx) => (
            <div
              key={idx}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg"
            >
              <div className="w-12 h-12 bg-blue-100 dark:bg-blue-900/30 rounded-lg flex items-center justify-center mb-4">
                {React.createElement(solution.icon, {
                  className: "w-6 h-6 text-blue-600 dark:text-blue-400",
                })}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {solution.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-sm">
                {solution.description}
              </p>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="flex flex-wrap justify-center gap-6">
          <button className="inline-flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
            Projektanfrage starten
            <ArrowRight className="ml-2 w-5 h-5" />
          </button>
          <button className="inline-flex items-center px-8 py-4 rounded-xl border-2 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 font-medium hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300 hover:-translate-y-1">
            Beratungstermin vereinbaren
            <Users className="ml-2 w-5 h-5" />
          </button>
        </div>

        {/* Technical Indicators */}
        <div className="mt-24 grid grid-cols-2 md:grid-cols-4 gap-8">
          {[
            { icon: Box, label: "Microservices", value: "Cloud-Native" },
            {
              icon: Network,
              label: "API Endpoints",
              value: "RESTful & GraphQL",
            },
            { icon: Lock, label: "Sicherheit", value: "Enterprise-Grade" },
            { icon: Laptop, label: "Entwicklung", value: "Agile & DevOps" },
          ].map((item, idx) => (
            <div key={idx} className="text-center">
              {React.createElement(item.icon, {
                className:
                  "w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3",
              })}
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {item.label}
              </div>
              <div className="font-semibold text-gray-900 dark:text-white">
                {item.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SoftwareSolutionsHero;
