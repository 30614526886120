import React, { useState, useEffect, useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from "framer-motion";
import {
  Coffee,
  Laptop,
  Map,
  Fingerprint,
  Building2,
  TreePine,
  Dumbbell,
  Smile,
  Music2,
  Wifi,
  Bike,
  PawPrint,
  ChefHat,
  Sprout,
  Zap,
  MonitorSmartphone,
  Sofa,
  Dog,
} from "lucide-react";

const AboutOfficeSection = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [hoveredBenefit, setHoveredBenefit] = useState(null);
  const [activeLocation, setActiveLocation] = useState(0);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, margin: "-100px" });

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9, 1], [0, 1, 1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2], [0.8, 1]);
  const springY = useSpring(y, { mass: 0.5, stiffness: 100, damping: 10 });

  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const locations = [
    {
      city: "Hamburg",
      address: "Speicherstadt",
      features: ["Historisches Gebäude", "Wasserblick", "Zentrale Lage"],
      image: "/api/placeholder/800/500",
    },
    {
      city: "Berlin",
      address: "Kreuzberg",
      features: ["Moderne Bürofläche", "Dachterrasse", "Urban Life"],
      image: "/api/placeholder/800/500",
    },
  ];

  const benefits = [
    {
      title: "Work Smart",
      description: "Flexible Arbeitszeiten & Remote-Optionen",
      icon: Laptop,
      gradient: "from-blue-500 to-cyan-500",
      details: [
        "Kernarbeitszeit 10-15 Uhr",
        "2 Remote Tage/Woche",
        "Digitale Tools",
      ],
    },
    {
      title: "Stay Healthy",
      description: "Gesundheit & Work-Life-Balance",
      icon: Dumbbell,
      gradient: "from-green-500 to-emerald-500",
      details: ["Urban Sports Club", "Mental Health Support", "Yoga Sessions"],
    },
    {
      title: "Feel Good",
      description: "Wohlfühlatmosphäre & Benefits",
      icon: Smile,
      gradient: "from-purple-500 to-pink-500",
      details: ["Bio Obst & Snacks", "Barista Kaffee", "Team Events"],
    },
    {
      title: "Grow Together",
      description: "Entwicklung & Weiterbildung",
      icon: Sprout,
      gradient: "from-amber-500 to-orange-500",
      details: ["5.000€ Bildungsbudget", "Konferenzbesuche", "Mentoring"],
    },
  ];

  const officeFeatures = [
    { icon: MonitorSmartphone, label: "Modernste Technik" },
    { icon: Sofa, label: "Chill-Out Areas" },
    { icon: Music2, label: "Silent Spaces" },
    { icon: Coffee, label: "Barista Station" },
    { icon: Bike, label: "Bike Storage" },
    { icon: Dog, label: "Dog Friendly" },
  ];

  return (
    <section
      ref={sectionRef}
      className={`relative py-32 ${
        isDarkMode ? "bg-gray-900" : "bg-white"
      } transition-colors duration-300`}
    >
      {/* Animated background patterns */}
      <motion.div className="absolute inset-0 opacity-20" style={{ opacity }}>
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(255,182,255,0.1),rgba(255,182,255,0)_50%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_60%,rgba(120,119,198,0.1),rgba(255,182,255,0)_50%)]" />
      </motion.div>

      <div className="relative max-w-7xl mx-auto px-4">
        {/* Header */}
        <motion.div className="text-center mb-20" style={{ scale }}>
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            className="inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-purple-500/10 to-pink-500/10 dark:from-purple-500/20 dark:to-pink-500/20 mb-6"
          >
            <Building2 className="w-5 h-5 text-purple-500 mr-2" />
            <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
              Unsere Standorte
            </span>
          </motion.div>

          <h2
            className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            <span className="block">Wo Großartiges </span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-rose-500">
              entsteht
            </span>
          </h2>

          <p
            className={`text-lg max-w-3xl mx-auto ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Unsere Büros sind mehr als nur Arbeitsplätze. Sie sind Orte der
            Inspiration, der Zusammenarbeit und des Wohlbefindens.
          </p>
        </motion.div>

        {/* Office Locations */}
        <motion.div className="mb-32" style={{ y: springY }}>
          <div className="grid lg:grid-cols-2 gap-8">
            {locations.map((location, idx) => (
              <motion.div
                key={idx}
                className={`relative overflow-hidden rounded-2xl ${
                  isDarkMode ? "bg-gray-800" : "bg-gray-50"
                } border border-gray-200/20`}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: idx * 0.2 }}
                onHoverStart={() => setActiveLocation(idx)}
                whileHover={{ scale: 1.02 }}
              >
                <div className="aspect-[4/3] relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                  <img
                    src={location.image}
                    alt={location.city}
                    className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                  />
                  <div className="absolute bottom-0 left-0 right-0 p-8 z-20">
                    <h3 className="text-3xl font-bold text-white mb-2">
                      {location.city}
                    </h3>
                    <p className="text-gray-200">{location.address}</p>
                  </div>
                </div>
                <div className="p-8">
                  <div className="flex flex-wrap gap-4">
                    {location.features.map((feature, fidx) => (
                      <motion.span
                        key={fidx}
                        className={`inline-flex items-center px-4 py-2 rounded-full ${
                          isDarkMode
                            ? "bg-gray-700 text-gray-300"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ delay: fidx * 0.1 }}
                      >
                        <Zap className="w-4 h-4 mr-2" />
                        {feature}
                      </motion.span>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Office Features */}
        <motion.div
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-32"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          {officeFeatures.map((feature, idx) => (
            <motion.div
              key={idx}
              className={`p-6 rounded-xl text-center ${
                isDarkMode
                  ? "bg-gray-800/50 hover:bg-gray-800"
                  : "bg-gray-50 hover:bg-white"
              } transition-all duration-300`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: idx * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              <feature.icon
                className={`w-8 h-8 mx-auto mb-4 ${
                  isDarkMode ? "text-purple-400" : "text-purple-500"
                }`}
              />
              <p
                className={`text-sm font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                {feature.label}
              </p>
            </motion.div>
          ))}
        </motion.div>

        {/* Benefits */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, idx) => (
            <motion.div
              key={idx}
              className={`relative p-8 rounded-2xl ${
                isDarkMode
                  ? "bg-gray-800/50 hover:bg-gray-800"
                  : "bg-gray-50 hover:bg-white"
              } backdrop-blur-sm border border-gray-200/20 overflow-hidden`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: idx * 0.1 }}
              onHoverStart={() => setHoveredBenefit(idx)}
              onHoverEnd={() => setHoveredBenefit(null)}
              whileHover={{ scale: 1.02 }}
            >
              <div
                className={`bg-gradient-to-br ${benefit.gradient} w-14 h-14 rounded-xl flex items-center justify-center mb-6`}
              >
                <benefit.icon className="w-7 h-7 text-white" />
              </div>
              <h3
                className={`text-xl font-bold mb-3 ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                {benefit.title}
              </h3>
              <p
                className={`${isDarkMode ? "text-gray-300" : "text-gray-600"}`}
              >
                {benefit.description}
              </p>
              <motion.div
                className="mt-6 space-y-3"
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: hoveredBenefit === idx ? 1 : 0,
                  height: hoveredBenefit === idx ? "auto" : 0,
                }}
                transition={{ duration: 0.3 }}
              >
                {benefit.details.map((detail, detailIdx) => (
                  <motion.div
                    key={detailIdx}
                    className="flex items-center space-x-2"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: detailIdx * 0.1 }}
                  >
                    <Zap
                      className={`w-4 h-4 ${
                        isDarkMode ? "text-purple-400" : "text-purple-500"
                      }`}
                    />
                    <span
                      className={`text-sm ${
                        isDarkMode ? "text-gray-400" : "text-gray-600"
                      }`}
                    >
                      {detail}
                    </span>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutOfficeSection;
