import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Code2,
  Sparkles,
  Package,
  Globe,
  Laptop,
  Award,
  ArrowRight,
  GitBranch,
  Github,
} from "lucide-react";

const PortfolioHero = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Sync with system/document dark mode
  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const stats = [
    { icon: Package, value: "50+", label: "Projekte" },
    { icon: Globe, value: "30+", label: "Kunden" },
    { icon: Award, value: "5+", label: "Jahre" },
  ];

  const handleContactScroll = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative min-h-[90vh] flex items-center">
      {/* Animated Background */}
      <div className="absolute inset-0 overflow-hidden">
        <div 
          className={`absolute top-0 left-0 w-full h-full opacity-40 ${
            isDarkMode ? 'bg-grid-white/[0.2]' : 'bg-grid-black/[0.2]'
          }`}
          style={{
            backgroundSize: '30px 30px',
            backgroundImage: `linear-gradient(to right, ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'} 1px, transparent 1px),
                             linear-gradient(to bottom, ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'} 1px, transparent 1px)`
          }}
        />
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-cyan-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000" />
        <div className="absolute top-40 left-40 w-80 h-80 bg-pink-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000" />
      </div>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 py-20">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            {/* Badge */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-100 to-indigo-100 dark:from-purple-900/50 dark:to-indigo-900/50 px-4 py-2 rounded-full"
            >
              <Sparkles className="w-5 h-5 text-purple-600 dark:text-purple-400" />
              <span className="text-sm font-medium text-purple-900 dark:text-purple-300">
                Full Stack Developer & Designer
              </span>
            </motion.div>

            {/* Heading */}
            <div className="space-y-4">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="text-4xl md:text-6xl font-bold"
              >
                <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                  Crafting Digital
                </span>
                <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 via-cyan-500 to-pink-500">
                  Excellence
                </span>
              </motion.h1>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="text-lg text-gray-600 dark:text-gray-300"
              >
                Transformiere Visionen in beeindruckende digitale Erlebnisse. 
                Mit innovativem Design und modernster Technologie entwickle ich 
                maßgeschneiderte Lösungen, die Ihre Ziele erreichen.
              </motion.p>
            </div>

            {/* CTA Buttons */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="flex flex-wrap gap-4"
            >
              <button
                onClick={handleContactScroll}
                className="inline-flex items-center px-6 py-3 rounded-xl bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium shadow-lg shadow-purple-500/30 hover:shadow-xl hover:shadow-purple-500/40 transition-all duration-300"
              >
                Projekt anfragen
                <ArrowRight className="w-5 h-5 ml-2" />
              </button>
              <a
                href="https://github.com/FaxFox11/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 rounded-xl border-2 border-gray-200 dark:border-gray-700 font-medium hover:bg-gray-50 dark:hover:bg-gray-800 transition-all duration-300"
              >
                <Github className="w-5 h-5 mr-2" />
                GitHub Profile
              </a>
            </motion.div>

            {/* Stats */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="grid grid-cols-3 gap-8 pt-8"
            >
              {stats.map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="inline-flex items-center justify-center w-12 h-12 rounded-2xl bg-purple-100 dark:bg-purple-900/50 mb-4">
                    {React.createElement(stat.icon, {
                      className: "w-6 h-6 text-purple-600 dark:text-purple-400",
                    })}
                  </div>
                  <div className="text-2xl font-bold text-gray-900 dark:text-white">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {stat.label}
                  </div>
                </div>
              ))}
            </motion.div>
          </motion.div>

          {/* Right Column - Visual Element */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.7, duration: 0.8 }}
            className="relative lg:h-[600px] hidden lg:block"
          >
            <div className="absolute inset-0 flex items-center justify-center">
              {/* Code Ring Animation */}
              <div className="relative w-[500px] h-[500px]">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
                  className="absolute inset-0 rounded-full border-2 border-dashed border-purple-200 dark:border-purple-900"
                />
                <motion.div
                  animate={{ rotate: -360 }}
                  transition={{ duration: 40, repeat: Infinity, ease: "linear" }}
                  className="absolute inset-8 rounded-full border-2 border-dashed border-cyan-200 dark:border-cyan-900"
                />
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 30, repeat: Infinity, ease: "linear" }}
                  className="absolute inset-16 rounded-full border-2 border-dashed border-pink-200 dark:border-pink-900"
                />
                
                {/* Center Icon */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <motion.div
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 4, repeat: Infinity }}
                    className="w-32 h-32 rounded-3xl bg-gradient-to-r from-purple-600 to-indigo-600 flex items-center justify-center shadow-2xl"
                  >
                    <Code2 className="w-16 h-16 text-white" />
                  </motion.div>
                </div>

                {/* Floating Tech Icons */}
                {[0, 60, 120, 180, 240, 300].map((degree, index) => (
                  <motion.div
                    key={index}
                    className="absolute top-0 left-1/2 -ml-8 w-16 h-16"
                    style={{
                      transformOrigin: "50% 250px",
                      transform: `rotate(${degree}deg)`,
                    }}
                    animate={{ rotate: [degree, degree + 360] }}
                    transition={{
                      duration: 20,
                      repeat: Infinity,
                      ease: "linear",
                      delay: -index * (20 / 6),
                    }}
                  >
                    <div
                      className="w-16 h-16 rounded-2xl bg-white dark:bg-gray-800 shadow-lg flex items-center justify-center"
                      style={{ transform: `rotate(-${degree}deg)` }}
                    >
                      {React.createElement([Laptop, Package, Globe, GitBranch, Award, Code2][index], {
                        className: "w-8 h-8 text-purple-600 dark:text-purple-400",
                      })}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioHero;