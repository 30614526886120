import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import {
  Code2,
  Smartphone,
  Globe,
  Database,
  Rocket,
  Server,
  Zap,
  Layout,
  Terminal,
  Shield,
  Cloud,
  Monitor,
  ArrowRight,
  ChevronRight,
} from "lucide-react";

const ServicesShowcase = () => {
  const [activeService, setActiveService] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const ROTATION_DURATION = 8000;
  const TOTAL_DURATION = 8000;

  const services = [
    {
      id: "software",
      title: "Individuelle Software",
      color: "from-blue-500 to-indigo-600",
      icon: Code2,
      description: "Maßgeschneiderte Softwarelösungen für Ihr Unternehmen",
      cta: "Software Projekt starten",
      path: "/services/software",
      metrics: [
        {
          label: "Enterprise Lösungen",
          value: "Skalierbar",
          icon: Server,
          details: [
            "Microservices Architektur",
            "Cloud-native Entwicklung",
            "Hohe Verfügbarkeit",
            "Performance-Optimierung",
            "Sicherheitsstandards",
          ],
        },
        {
          label: "Technologie Stack",
          value: "Modern",
          icon: Terminal,
          details: [
            "React & Node.js",
            "Python Backend",
            ".NET Core",
            "Docker & Kubernetes",
            "CI/CD Pipeline",
          ],
        },
        {
          label: "Entwicklung",
          value: "Agil",
          icon: Rocket,
          details: [
            "Schnelle Iterationen",
            "Regelmäßige Updates",
            "Quality Assurance",
            "Code Reviews",
            "Agile Methodik",
          ],
        },
      ],
    },
    {
      id: "mobile",
      title: "Mobile Apps",
      color: "from-purple-500 to-pink-600",
      icon: Smartphone,
      description: "Native und Cross-Platform Mobile Anwendungen",
      cta: "App Entwicklung anfragen",
      path: "/services/mobile",
      metrics: [
        {
          label: "App Entwicklung",
          value: "Cross-Platform",
          icon: Layout,
          details: [
            "iOS Entwicklung",
            "Android Entwicklung",
            "React Native",
            "Flutter Apps",
            "PWA Lösungen",
          ],
        },
        {
          label: "Performance",
          value: "Optimiert",
          icon: Zap,
          details: [
            "Native Performance",
            "Offline-Fähigkeit",
            "Push Notifications",
            "App Analytics",
            "A/B Testing",
          ],
        },
        {
          label: "Integration",
          value: "Nahtlos",
          icon: Cloud,
          details: [
            "Backend Services",
            "Cloud Storage",
            "API Integration",
            "Social Media",
            "Payment Gateway",
          ],
        },
      ],
    },
    {
      id: "web",
      title: "Webentwicklung",
      color: "from-emerald-500 to-teal-600",
      icon: Globe,
      description: "Moderne Webseiten und Progressive Web Apps",
      cta: "Website planen",
      path: "/services/web",
      metrics: [
        {
          label: "Web Design",
          value: "Responsive",
          icon: Monitor,
          details: [
            "Mobile-First Design",
            "SEO Optimierung",
            "Performance Focus",
            "UX/UI Design",
            "Brand Identity",
          ],
        },
        {
          label: "Technologien",
          value: "State-of-Art",
          icon: Code2,
          details: [
            "Next.js & React",
            "Tailwind CSS",
            "Headless CMS",
            "JAMstack",
            "WordPress",
          ],
        },
        {
          label: "Features",
          value: "Modern",
          icon: Shield,
          details: [
            "SSL Security",
            "CDN Integration",
            "Analytics",
            "DSGVO konform",
            "Barrierefreiheit",
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    let timer;
    if (autoPlay) {
      timer = setInterval(() => {
        setActiveService((prev) => (prev + 1) % services.length);
      }, TOTAL_DURATION);
    }
    return () => clearInterval(timer);
  }, [autoPlay]);

  const MetricCard = ({ metric, serviceColor, to }) => (
    <Link to={to}>
      <motion.div
        className="bg-white dark:bg-gray-800 rounded-2xl p-4 md:p-8 shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer group"
        whileHover={{ y: -5, transition: { duration: 0.2 } }}
      >
        <div
          className={`inline-flex p-3 md:p-4 rounded-xl bg-gradient-to-br ${serviceColor} mb-4 md:mb-6`}
        >
          {React.createElement(metric.icon, {
            className: "w-5 h-5 md:w-6 md:h-6 text-white",
          })}
        </div>

        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-800 dark:text-white flex items-center">
          {metric.label}
          <ChevronRight className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity" />
        </h3>

        <p className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-400 mb-4">
          {metric.value}
        </p>

        <div className="space-y-2 md:space-y-3">
          {metric.details.map((detail, idx) => (
            <motion.div
              key={idx}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: idx * 0.1 }}
              className="flex items-center text-gray-600 dark:text-gray-300"
            >
              <div className="w-1.5 h-1.5 rounded-full bg-current mr-2" />
              <span className="text-xs md:text-sm">{detail}</span>
            </motion.div>
          ))}
        </div>

        <motion.div className="h-1 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden mt-4 md:mt-6">
          <motion.div
            key={`progress-${metric.label}-${activeService}`}
            className={`h-full bg-gradient-to-r ${serviceColor}`}
            initial={{ width: "0%" }}
            animate={{ width: "100%" }}
            transition={{
              duration: ROTATION_DURATION / 1000,
              ease: "linear",
            }}
          />
        </motion.div>
      </motion.div>
    </Link>
  );

  return (
    <section className="relative bg-white dark:bg-gray-900 py-12 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="text-4xl md:text-6xl font-bold mb-4 md:mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
              Unsere Dienstleistungen
            </span>
          </h2>
          <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Innovative Technologielösungen für die digitale Transformation Ihres
            Unternehmens
          </p>
        </motion.div>

        {/* Auto-play Toggle */}
        <div className="flex justify-center gap-4 mb-8 md:mb-12">
          <button
            onClick={() => setAutoPlay(!autoPlay)}
            className="px-4 md:px-6 py-2 md:py-3 rounded-xl bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 
              hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 flex items-center space-x-2"
          >
            <Zap className="w-4 h-4 md:w-5 md:h-5" />
            <span className="text-sm md:text-base">
              {autoPlay ? "Auto-Rotation An" : "Auto-Rotation Aus"}
            </span>
          </button>
        </div>

        {/* Service Navigation */}
        <div className="flex flex-wrap justify-center gap-2 md:gap-4 mb-8 md:mb-16">
          {services.map((service, index) => (
            <motion.button
              key={service.id}
              onClick={() => {
                setActiveService(index);
                setAutoPlay(false);
              }}
              className={`
                px-4 md:px-6 py-2 md:py-3 rounded-xl transition-all duration-300 text-sm md:text-base
                ${
                  activeService === index
                    ? `bg-gradient-to-r ${service.color} text-white shadow-lg`
                    : "bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                }
              `}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="flex items-center space-x-2">
                {React.createElement(service.icon, {
                  className: "w-4 h-4 md:w-5 md:h-5",
                })}
                <span className="font-medium">{service.title}</span>
              </div>
            </motion.button>
          ))}
        </div>

        {/* Service Content */}
        <div className="relative pb-20 md:pb-0">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeService}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="w-full"
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
                {services[activeService].metrics.map((metric, idx) => (
                  <motion.div
                    key={idx}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: idx * 0.2 }}
                  >
                    <MetricCard
                      metric={metric}
                      serviceColor={services[activeService].color}
                      to={services[activeService].path}
                    />
                  </motion.div>
                ))}
              </div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="text-center mt-8 md:mt-12 space-y-4 md:space-y-6"
              >
                <p className="text-base md:text-lg text-gray-600 dark:text-gray-300">
                  {services[activeService].description}
                </p>

                {/* CTA Button */}
                <Link to={services[activeService].path}>
                  <motion.button
                    key={`cta-${activeService}`}
                    className={`
                      bg-gradient-to-r ${services[activeService].color}
                      text-white px-6 md:px-8 py-3 md:py-4 rounded-xl shadow-lg
                      hover:shadow-xl transition-shadow duration-300
                      flex items-center space-x-2 mx-auto text-sm md:text-base
                    `}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <span className="font-medium">
                      {services[activeService].cta}
                    </span>
                    <ArrowRight className="w-4 h-4 md:w-5 md:h-5" />
                  </motion.button>
                </Link>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default ServicesShowcase;
