import React from "react";
import { motion } from "framer-motion";
import {
  Sparkles,
  Rocket,
  ArrowRight,
  Zap,
  Layout,
  Code2,
  Palette,
  Terminal,
  CheckCircle2,
  GitBranch,
  Globe,
} from "lucide-react";

const RapidDevelopment = () => {
  const handleContactScroll = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const timeline = [
    {
      day: "Tag 1-2",
      title: "Design & Planung",
      description: "Konzeption und Design-Entwicklung basierend auf Ihren Anforderungen",
      icon: Layout,
      gradient: "from-blue-500 to-cyan-500",
      features: ["Wireframing", "UI/UX Design", "Technische Planung"]
    },
    {
      day: "Tag 3-4",
      title: "Entwicklung",
      description: "Umsetzung des Designs mit modernsten Technologien",
      icon: Code2,
      gradient: "from-purple-500 to-pink-500",
      features: ["Frontend-Entwicklung", "Responsive Design", "Performance-Optimierung"]
    },
    {
      day: "Tag 5-6",
      title: "Testing & Optimierung",
      description: "Umfassende Tests und Feinschliff",
      icon: Terminal,
      gradient: "from-orange-500 to-red-500",
      features: ["Cross-Browser Tests", "Mobile Testing", "SEO Optimierung"]
    },
    {
      day: "Tag 7",
      title: "Launch",
      description: "Finales Review und Veröffentlichung",
      icon: Rocket,
      gradient: "from-green-500 to-emerald-500",
      features: ["Deployment", "DNS Setup", "Live-Schaltung"]
    }
  ];

  // Animation variants for cards
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      }
    },
    hover: {
      scale: 1.02,
      boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  };

  // Animation variants for features
  const featureVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.3
      }
    })
  };

  // Animation variants for the flowing underline
  const underlineVariants = {
    initial: {
      pathLength: 0,
      pathOffset: 1,
    },
    animate: {
      pathLength: 1,
      pathOffset: 0,
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  // Sparkle animation variants
  const sparkleVariants = {
    animate: {
      scale: [0, 1, 0],
      opacity: [0, 1, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatDelay: 1
      }
    }
  };

  return (
    <div className="relative py-20 overflow-hidden">
      {/* Background Elements with enhanced animations */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute inset-0 opacity-40 dark:opacity-20"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(0 0 0 / 0.1) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />
        <motion.div 
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.2, 0.3, 0.2]
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="absolute top-40 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl"
        />
        <motion.div 
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.2, 0.25, 0.2]
          }}
          transition={{
            duration: 6,
            repeat: Infinity,
            ease: "easeInOut",
            delay: 1
          }}
          className="absolute -bottom-20 left-1/4 w-96 h-96 bg-cyan-500/20 rounded-full mix-blend-multiply filter blur-3xl"
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Header Section with enhanced animations */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            whileHover={{ scale: 1.05 }}
            className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-100 to-indigo-100 dark:from-purple-900/50 dark:to-indigo-900/50 px-6 py-2 rounded-full mb-6 cursor-pointer"
          >
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            >
              <Zap className="w-5 h-5 text-purple-600 dark:text-purple-400" />
            </motion.div>
            <span className="text-base font-medium text-purple-900 dark:text-purple-300">
              7-Tage Sprint
            </span>
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-4xl md:text-5xl font-bold mb-6"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300">
              Von der Idee zur
            </span>
            <motion.span 
              className="relative ml-2 inline-block"
              whileHover={{ scale: 1.05 }}
            >
              <span className="relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-cyan-500 to-pink-500">
                Website in 7 Tagen
              </span>
              {/* Animated underline */}
              <svg
                className="absolute -bottom-2 left-0 w-full h-4 overflow-visible"
                preserveAspectRatio="none"
              >
                <motion.path
                  d="M0,0 C100,0 200,0 300,0"
                  fill="none"
                  stroke="url(#gradient-line)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  variants={underlineVariants}
                  initial="initial"
                  animate="animate"
                  className="w-full"
                />
                <defs>
                  <linearGradient id="gradient-line" x1="0" y1="0" x2="100%" y2="0">
                    <stop offset="0%" stopColor="#9333EA" />
                    <stop offset="50%" stopColor="#06B6D4" />
                    <stop offset="100%" stopColor="#DB2777" />
                  </linearGradient>
                </defs>
              </svg>
              {/* Sparkle effects */}
              <motion.div
                className="absolute -top-2 -right-2"
                variants={sparkleVariants}
                animate="animate"
              >
                <Sparkles className="w-4 h-4 text-purple-400" />
              </motion.div>
            </motion.span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
          >
            Unser agiler Entwicklungsprozess garantiert schnelle Ergebnisse ohne Kompromisse bei der Qualität.
          </motion.p>
        </div>

        {/* Timeline Section with enhanced animations */}
        <div className="relative mb-20">
          <motion.div 
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            viewport={{ once: true }}
            transition={{ duration: 1.5, ease: "easeOut" }}
            className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-gradient-to-b from-purple-200 to-cyan-200 dark:from-purple-900 dark:to-cyan-900 rounded-full"
          />
          
          <div className="space-y-20">
            {timeline.map((phase, index) => (
              <motion.div
                key={phase.day}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className={`relative flex items-center gap-8 ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                }`}
              >
                {/* Timeline Node with enhanced animations */}
                <motion.div
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  transition={{ duration: 0.3 }}
                  className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center w-12 h-12 rounded-full bg-white dark:bg-gray-800 shadow-lg border-4 border-purple-100 dark:border-purple-900"
                >
                  {React.createElement(phase.icon, {
                    className: "w-5 h-5 text-purple-600 dark:text-purple-400",
                  })}
                </motion.div>

                {/* Content Card with enhanced animations */}
                <motion.div 
                  className="w-full md:w-1/2"
                  variants={cardVariants}
                  initial="hidden"
                  whileInView="visible"
                  whileHover="hover"
                  viewport={{ once: true }}
                >
                  <div className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl p-8 border border-gray-200/50 dark:border-gray-700/50 transition-all duration-300">
                    <motion.div 
                      className={`inline-flex items-center gap-2 px-4 py-1 rounded-full text-white text-sm font-medium bg-gradient-to-r ${phase.gradient} mb-4`}
                      whileHover={{ scale: 1.05 }}
                    >
                      {phase.day}
                    </motion.div>
                    
                    <motion.h3 
                      className="text-2xl font-bold text-gray-900 dark:text-white mb-3"
                      whileHover={{ x: 10 }}
                    >
                      {phase.title}
                    </motion.h3>
                    
                    <p className="text-gray-600 dark:text-gray-300 mb-6">
                      {phase.description}
                    </p>

                    <div className="space-y-2">
                      {phase.features.map((feature, idx) => (
                        <motion.div
                          key={idx}
                          custom={idx}
                          variants={featureVariants}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          whileHover={{ x: 10 }}
                          className="flex items-center gap-2 text-gray-600 dark:text-gray-300"
                        >
                          <motion.div
                            whileHover={{ rotate: 360 }}
                            transition={{ duration: 0.3 }}
                          >
                            <CheckCircle2 className="w-5 h-5 text-green-500" />
                          </motion.div>
                          <span>{feature}</span>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* CTA Section with enhanced animations */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="text-center"
        >
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="inline-flex flex-col items-center bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl p-8 border border-gray-200/50 dark:border-gray-700/50"
          >
            <motion.div
              animate={{ 
                y: [0, -10, 0],
                rotate: [0, 10, -10, 0]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <Rocket className="w-12 h-12 text-purple-600 dark:text-purple-400 mb-4" />
            </motion.div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Bereit für den Sprint?
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6 max-w-2xl">
              In nur 7 Tagen von der Idee zur fertigen Website. Starten Sie jetzt Ihr Projekt mit unserem bewährten Sprint-Prozess.
            </p>
            <motion.button
              onClick={handleContactScroll}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-xl text-lg font-medium shadow-lg shadow-purple-500/25 hover:shadow-xl hover:shadow-purple-500/30 transition-all duration-300"
            >
              Sprint Starten
              <motion.div
                animate={{ x: [0, 5, 0] }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <ArrowRight className="w-5 h-5" />
              </motion.div>
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default RapidDevelopment;