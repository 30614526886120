import React from "react";
import { motion } from "framer-motion";
import {
  Building2,
  Users,
  Search,
  BarChart,
  Layers,
  Code2,
  Rocket,
  Target,
  Shield,
  Globe2,
  Database,
  LineChart,
  Network,
  Workflow,
  Settings,
  Zap,
  Server,
  Cloud,
} from "lucide-react";

const EnterpriseProcess = () => {
  const processes = [
    {
      phase: "Analysephase",
      icon: Building2,
      title: "Unternehmensanalyse",
      description:
        "Ganzheitliche Analyse Ihrer Unternehmensarchitektur, Geschäftsprozesse und digitalen Transformationsziele.",
      metrics: [
        { value: "60+", label: "Analysierte Prozesse" },
        { value: "25+", label: "Stakeholder-Interviews" },
        { value: "100%", label: "Abteilungsabdeckung" },
      ],
      capabilities: [
        {
          icon: Users,
          title: "Stakeholder-Mapping",
          description:
            "Umfassende Identifikation aller Entscheidungsträger und Anwender",
        },
        {
          icon: Search,
          title: "Prozessanalyse",
          description:
            "Detaillierte Analyse bestehender Workflows und Optimierungspotenziale",
        },
        {
          icon: BarChart,
          title: "ROI-Modellierung",
          description: "Fundierte Kosten-Nutzen-Analyse und ROI-Prognosen",
        },
      ],
    },
    {
      phase: "Architekturplanung",
      icon: Layers,
      title: "Enterprise-Architektur",
      description:
        "Entwicklung einer skalierbaren, sicheren und zukunftsfähigen technischen Architektur für Ihr Unternehmen.",
      metrics: [
        { value: "99,9%", label: "Verfügbarkeit" },
        { value: "5x", label: "Performance-Steigerung" },
        { value: "100%", label: "Ausfallsicherheit" },
      ],
      capabilities: [
        {
          icon: Database,
          title: "Datenarchitektur",
          description:
            "Skalierbare Datenbankarchitektur mit höchster Verfügbarkeit",
        },
        {
          icon: Shield,
          title: "Sicherheitskonzept",
          description: "Enterprise-grade Sicherheit und Compliance-Maßnahmen",
        },
        {
          icon: Network,
          title: "Systemintegration",
          description: "Nahtlose Integration in bestehende Systemlandschaften",
        },
      ],
    },
    {
      phase: "Entwicklung & Integration",
      icon: Code2,
      title: "Enterprise-Entwicklung",
      description:
        "Implementierung Ihrer Lösung mit modernster Technologie und nach höchsten Qualitätsstandards.",
      metrics: [
        { value: "100%", label: "Code-Abdeckung" },
        { value: "Täglich", label: "Deployments" },
        { value: "<0,5s", label: "Reaktionszeit" },
      ],
      capabilities: [
        {
          icon: Workflow,
          title: "CI/CD-Pipeline",
          description: "Automatisierte Test- und Deployment-Prozesse",
        },
        {
          icon: Server,
          title: "Microservices",
          description: "Skalierbare Microservices-Architektur",
        },
        {
          icon: Cloud,
          title: "Cloud-Infrastruktur",
          description: "Enterprise-Cloud-Deployment und -Skalierung",
        },
      ],
    },
    {
      phase: "Launch & Skalierung",
      icon: Rocket,
      title: "Globaler Rollout",
      description:
        "Orchestrierung eines reibungslosen globalen Launches mit umfassendem Monitoring und Support.",
      metrics: [
        { value: "24/7", label: "Enterprise Support" },
        { value: "Global", label: "CDN-Abdeckung" },
        { value: "Echtzeit", label: "Monitoring" },
      ],
      capabilities: [
        {
          icon: Globe2,
          title: "Globales Deployment",
          description: "Weltweite Infrastruktur und Content-Delivery",
        },
        {
          icon: LineChart,
          title: "Performance-Monitoring",
          description: "Echtzeit-Metriken und Performance-Analytics",
        },
        {
          icon: Zap,
          title: "Automatische Skalierung",
          description: "Bedarfsgerechte Ressourcenanpassung in Echtzeit",
        },
      ],
    },
  ];

  return (
    <div className="w-full bg-gradient-to-b from-slate-50 to-slate-100 dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-20">
        {/* Header */}
        <div className="text-center mb-20">
          <motion.h2
            className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600 mb-6"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Enterprise-Entwicklungsprozess
          </motion.h2>
          <motion.p
            className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Ein ganzheitlicher Ansatz für skalierbare, sichere und
            hochperformante Unternehmenslösungen
          </motion.p>
        </div>

        {/* Process Sections */}
        <div className="space-y-24">
          {processes.map((process, index) => (
            <motion.div
              key={process.phase}
              className="relative"
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.7, delay: index * 0.1 }}
            >
              {/* Connection Line */}
              {index < processes.length - 1 && (
                <div className="absolute h-24 w-px bg-gradient-to-b from-blue-500 to-indigo-500 left-1/2 -bottom-24 transform -translate-x-1/2" />
              )}

              {/* Phase Content */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                {/* Left Column - Info */}
                <div className="space-y-8">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 rounded-2xl bg-gradient-to-r from-blue-500 to-indigo-500">
                      {React.createElement(process.icon, {
                        className: "w-8 h-8 text-white",
                      })}
                    </div>
                    <div>
                      <div className="text-sm font-medium text-blue-600 dark:text-blue-400">
                        {process.phase}
                      </div>
                      <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                        {process.title}
                      </h3>
                    </div>
                  </div>

                  <p className="text-gray-600 dark:text-gray-300 text-lg">
                    {process.description}
                  </p>

                  {/* Metrics */}
                  <div className="grid grid-cols-3 gap-6">
                    {process.metrics.map((metric, i) => (
                      <div key={i} className="text-center">
                        <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                          {metric.value}
                        </div>
                        <div className="text-sm text-gray-600 dark:text-gray-400">
                          {metric.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Right Column - Capabilities */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                  <div className="space-y-6">
                    {process.capabilities.map((capability, i) => (
                      <motion.div
                        key={i}
                        className="flex items-start space-x-4"
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: i * 0.2 }}
                      >
                        <div className="p-2 rounded-lg bg-blue-50 dark:bg-gray-700">
                          {React.createElement(capability.icon, {
                            className:
                              "w-6 h-6 text-blue-600 dark:text-blue-400",
                          })}
                        </div>
                        <div>
                          <h4 className="font-semibold text-gray-900 dark:text-white">
                            {capability.title}
                          </h4>
                          <p className="text-gray-600 dark:text-gray-400">
                            {capability.description}
                          </p>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          className="mt-24 text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
        >
          <div className="inline-block p-1 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full">
            <button className="group px-8 py-4 bg-white dark:bg-gray-900 rounded-full transition-all duration-300 hover:bg-transparent">
              <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent font-semibold text-lg group-hover:text-white">
                Beratungstermin vereinbaren
              </span>
            </button>
          </div>
          <p className="mt-4 text-gray-600 dark:text-gray-400">
            Erhalten Sie eine maßgeschneiderte Lösung für Ihr Unternehmen
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default EnterpriseProcess;
