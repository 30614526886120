import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Smartphone, Code2, Zap, ArrowRight } from "lucide-react";

const MobileAppHero = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  // Sync with system/document dark mode
  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const handleRequestQuote = () => {
    navigate("/contact");
  };

  return (
    <div
      className={`relative min-h-[90vh] ${
        isDarkMode ? "bg-gray-900" : "bg-gray-50"
      } transition-colors duration-300`}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_80%,rgba(255,182,255,0.1),rgba(255,182,255,0)_50%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_20%,rgba(120,119,198,0.1),rgba(255,182,255,0)_50%)]" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 pt-32 pb-20">
        <motion.div
          className="text-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            variants={itemVariants}
            className="inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-violet-500/10 to-purple-500/10 dark:from-violet-500/20 dark:to-purple-500/20 mb-8"
          >
            <Zap className="w-5 h-5 text-violet-500 mr-2" />
            <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-violet-500 to-purple-500">
              Native & Cross-Platform Apps
            </span>
          </motion.div>

          <motion.h1
            variants={itemVariants}
            className="text-5xl sm:text-6xl lg:text-7xl font-bold tracking-tight mb-8"
          >
            <span
              className={`block mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Mobilität, die
            </span>
            <span className="relative">
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-violet-500 via-purple-500 to-cyan-500 pb-2">
                Grenzen überwindet
              </span>
              <motion.div
                className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-violet-500 via-purple-500 to-cyan-500"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ delay: 0.5, duration: 0.8 }}
              />
            </span>
          </motion.h1>

          <motion.p
            variants={itemVariants}
            className={`text-xl mb-12 max-w-2xl mx-auto ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Ob iOS, Android oder Cross-Platform - wir entwickeln mobile Apps,
            die Ihre Geschäftsidee in eine fantastische Benutzererfahrung
            übersetzen.
          </motion.p>

          <motion.div
            variants={itemVariants}
            className="flex flex-wrap justify-center gap-4"
          >
            <motion.button
              onClick={handleRequestQuote}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-violet-500 via-purple-500 to-cyan-500 text-white font-medium shadow-lg hover:shadow-xl transition-all duration-300"
            >
              Jetzt Angebot anfordern
              <ArrowRight className="w-5 h-5 ml-2" />
            </motion.button>
            <Link to="/portfolio">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`inline-flex items-center px-8 py-4 rounded-xl border ${
                  isDarkMode
                    ? "border-gray-700 text-gray-300 hover:bg-gray-800/50"
                    : "border-gray-200 text-gray-600 hover:bg-gray-50"
                } font-medium transition-all duration-300`}
              >
                Portfolio ansehens
                <Smartphone className="w-5 h-5 ml-2" />
              </motion.button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default MobileAppHero;
