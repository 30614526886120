import React from "react";
import { Wrench, Clock, Zap } from "lucide-react";

const InWork = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-20">
        <div className="mb-12 text-center">
          <div className="flex items-center justify-center gap-3 mb-6">
            <Wrench className="w-10 h-10 text-blue-500 animate-pulse" />
            <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white">
              Website in Entwicklung
            </h1>
          </div>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Wir arbeiten an den letzten Details für etwas Außergewöhnliches.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto mb-12">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
            <Clock className="w-8 h-8 text-blue-500 mb-4" />
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-3">
              Demnächst verfügbar
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Unsere neue Website wird in wenigen Tagen bereit sein -
              vollgepackt mit spannenden Funktionen und einem frischen Design.
            </p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
            <Zap className="w-8 h-8 text-blue-500 mb-4" />
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-3">
              Schnelle Entwicklung
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Wir nutzen modernste KI-Technologie für einen schnellen und
              effizienten Entwicklungsprozess.
            </p>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
            <Wrench className="w-8 h-8 text-blue-500 mb-4" />
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-3">
              Finale Tests
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Unser Team führt gründliche Tests durch, um sicherzustellen, dass
              alles perfekt für Sie funktioniert.
            </p>
          </div>
        </div>

        <div className="text-center">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Das können Sie erwarten
            </h2>
            <ul className="text-gray-600 dark:text-gray-400 space-y-3 mb-6">
              <li>• Moderne und intuitive Benutzeroberfläche</li>
              <li>• Blitzschnelle Performance</li>
              <li>• Umfangreicher Funktionsumfang</li>
              <li>• Optimiert für mobile Geräte</li>
            </ul>
            <p className="text-gray-600 dark:text-gray-400">
              Vielen Dank für Ihre Geduld! Wir arbeiten mit Hochdruck daran,
              Ihnen das bestmögliche Erlebnis zu bieten.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InWork;
