import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Sparkles,
  Clock,
  ArrowRight,
  Check,
  Package,
  Rocket,
  Zap,
} from "lucide-react";
import { motion } from "framer-motion"; // Note: This would need to be installed

const WebDesignHero = () => {
  const [selectedType, setSelectedType] = useState(null);
  const navigate = useNavigate();

  const offerEndDate = "30. April 2024";

  const projectTypes = [
    {
      id: "special",
      icon: Zap,
      title: "Website Konzept",
      price: "49€",
      originalPrice: "299€",
      description: "Professionelles One-Page Website Konzept in 7 Tagen",
      features: [
        "Responsive Design",
        "High Performance",
        "80%+ SEO-Score",
        "Express Lieferung",
        "3 Revisionen inklusive",
      ],
      badge: "Limitiert",
      path: "/contact",
    },
    {
      id: "business",
      icon: Package,
      title: "Business Website",
      price: "ab 1.499€",
      description: "Ihre vollständige Unternehmens-Präsenz",
      features: [
        "Mehrseiten-Website",
        "Content-Management",
        "SEO Optimierung",
        "Analytics Integration",
      ],
      path: "/contact",
    },
    {
      id: "shop",
      icon: Rocket,
      title: "Online Shop",
      price: "ab 2.499€",
      description: "Professioneller E-Commerce Shop",
      features: [
        "Produkt-Management",
        "Sichere Bezahlung",
        "Lagerverwaltung",
        "Marketing-Tools",
      ],
      path: "/contact",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-16">
        {/* Hero Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <div className="inline-flex items-center justify-center p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full mb-6">
            <Clock className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
            <span className="text-blue-600 dark:text-blue-400 font-medium">
              Limitiertes Angebot bis zum {offerEndDate}
            </span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">
            Ihre neue Website
            <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent block mt-2">
              schnell & professionell
            </span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Wählen Sie das passende Paket für Ihre Bedürfnisse - jetzt mit
            exklusivem Schnell-Start Angebot!
          </p>
        </motion.div>

        {/* Project Type Cards */}
        <div className="grid md:grid-cols-3 gap-6 mb-12">
          {projectTypes.map((type, index) => (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              key={type.id}
              className={`
                relative overflow-hidden rounded-2xl transition-all duration-300
                ${
                  type.id === "special"
                    ? "bg-gradient-to-br from-blue-600 to-purple-600 text-white"
                    : "bg-white dark:bg-gray-800"
                }
              `}
            >
              {type.badge && (
                <div className="absolute top-4 right-4 bg-yellow-400 text-black text-sm font-semibold px-3 py-1 rounded-full">
                  {type.badge}
                </div>
              )}
              <div className="p-6">
                <div className="flex flex-col items-center text-center">
                  {React.createElement(type.icon, {
                    className: `w-12 h-12 mb-4 ${
                      type.id === "special"
                        ? "text-white"
                        : "text-blue-600 dark:text-blue-400"
                    }`,
                  })}
                  <h3
                    className={`text-xl font-semibold mb-2 ${
                      type.id === "special"
                        ? "text-white"
                        : "text-gray-900 dark:text-white"
                    }`}
                  >
                    {type.title}
                  </h3>
                  <div className="mb-4">
                    {type.originalPrice && (
                      <span className="line-through text-sm opacity-75 mr-2">
                        {type.originalPrice}
                      </span>
                    )}
                    <span className="text-2xl font-bold">{type.price}</span>
                  </div>
                  <p
                    className={`text-sm mb-6 ${
                      type.id === "special"
                        ? "text-blue-100"
                        : "text-gray-600 dark:text-gray-300"
                    }`}
                  >
                    {type.description}
                  </p>
                  <ul className="space-y-3 text-left w-full mb-6">
                    {type.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center">
                        <Check className="w-5 h-5 mr-2 flex-shrink-0" />
                        <span className="text-sm">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`
                      w-full py-3 px-4 rounded-xl font-medium
                      ${
                        type.id === "special"
                          ? "bg-white text-blue-600 hover:bg-blue-50"
                          : "bg-blue-600 text-white hover:bg-blue-700"
                      }
                    `}
                  >
                    Jetzt starten
                    <ArrowRight className="w-4 h-4 ml-2 inline-block" />
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Trust Indicators */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-16 text-center"
        >
          <div className="flex flex-wrap justify-center gap-8 text-gray-600 dark:text-gray-300 text-sm">
            <span className="flex items-center">
              <Check className="w-5 h-5 mr-2 text-green-500" />
              100% Zufriedenheitsgarantie
            </span>
            <span className="flex items-center">
              <Check className="w-5 h-5 mr-2 text-green-500" />
              Über 500 erfolgreiche Projekte
            </span>
            <span className="flex items-center">
              <Check className="w-5 h-5 mr-2 text-green-500" />
              Persönliche Betreuung
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WebDesignHero;
