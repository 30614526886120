import React from "react";
import { motion } from "framer-motion";
import {
  Twitter,
  Linkedin,
  Instagram,
  Mail,
  Phone,
  MapPin,
} from "lucide-react";

const FooterSection = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 pt-20 pb-10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16">
          {/* Company Info */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-6"
          >
            <div className="flex items-center space-x-3 text-blue-400">
              <MapPin className="w-6 h-6" />
              <span>Goldener Spiegel 6, 57074 Siegen</span>
            </div>
            <div className="flex items-center space-x-3 text-blue-400">
              <Phone className="w-6 h-6" />
              <a href="tel:+491707238166" className="hover:text-blue-300">
                +49 170 7238166
              </a>
            </div>
            <div className="flex items-center space-x-3 text-blue-400">
              <Mail className="w-6 h-6" />
              <a href="mailto:info@tru-tec.de" className="hover:text-blue-300">
                info@tru-tec.de
              </a>
            </div>
          </motion.div>

          {/* Quick Links */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold text-white">Schnelle Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/Home" className="hover:text-blue-400">
                  Home
                </a>
              </li>
              <li>
                <a href="/Home" className="hover:text-blue-400">
                  Services
                </a>
              </li>
              <li>
                <a href="Portfolio" className="hover:text-blue-400">
                  Projekte
                </a>
              </li>
              <li>
                <a href="Imprint" className="hover:text-blue-400">
                  Impressum
                </a>
              </li>
              <li>
                <a href="PrivacyPolicy" className="hover:text-blue-400">
                  Datenschutz
                </a>
              </li>
            </ul>
          </motion.div>

          {/* Social Links */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold text-white">Folgen Sie uns</h3>
            <div className="flex items-center space-x-4">
              <a href="#" className="text-blue-400 hover:text-blue-300">
                <Twitter className="w-6 h-6" />
              </a>
              <a href="#" className="text-blue-400 hover:text-blue-300">
                <Linkedin className="w-6 h-6" />
              </a>
              <a href="#" className="text-blue-400 hover:text-blue-300">
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="border-t border-gray-700 pt-6 text-center text-gray-500 space-y-2"
        >
          <p>&copy; 2024 TruTec. Alle Rechte vorbehalten.</p>
          <div className="flex justify-center space-x-4">
            <a href="Imprint" className="hover:text-blue-400">
              Impressum
            </a>
            <a href="PrivacyPolicy" className="hover:text-blue-400">
              Datenschutz
            </a>
            <a href="#" className="hover:text-blue-400">
              AGB
            </a>
          </div>
        </motion.div>
      </div>
    </footer>
  );
};

export default FooterSection;
