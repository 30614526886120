import React from "react";

import SoftwareSolutionsHero from "../components/CustomDevSections/SoftwareSolutionsHero";

const Softwaredevelopement = () => {
  return (
    <div>
      <SoftwareSolutionsHero />
    </div>
  );
};

export default Softwaredevelopement;
