import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Smartphone,
  Store,
  Share2,
  Zap,
  Shield,
  Cloud,
  Palette,
  Globe,
  MessageSquare,
  ArrowRight,
  Check,
  Sparkles,
  Settings,
  Code,
  AppWindow,
  Rocket,
} from "lucide-react";

const MobileDevHero = () => {
  const [activePlatform, setActivePlatform] = useState("ios");

  const platforms = [
    {
      id: "ios",
      icon: AppWindow,
      title: "iOS Entwicklung",
      description: "Native Apps für das Apple Ökosystem",
      features: [
        "Swift & SwiftUI",
        "iOS Design Guidelines",
        "App Store Optimierung",
      ],
      marketShare: "54.3%",
      tools: ["Xcode", "TestFlight", "CoreData"],
    },
    {
      id: "android",
      icon: Smartphone,
      title: "Android Entwicklung",
      description: "Native Apps für das Android Ökosystem",
      features: [
        "Kotlin & Jetpack Compose",
        "Material Design 3",
        "Play Store Optimierung",
      ],
      marketShare: "45.7%",
      tools: ["Android Studio", "Firebase", "Room DB"],
    },
    {
      id: "cross",
      icon: Share2,
      title: "Cross-Platform",
      description: "Eine Codebasis für alle Plattformen",
      features: [
        "React Native & Flutter",
        "Plattform-spezifisches Design",
        "Code Sharing",
      ],
      marketShare: "32.8%",
      tools: ["VS Code", "Expo", "Redux"],
    },
  ];

  const services = [
    {
      icon: Palette,
      title: "UI/UX Design",
      description: "Nutzerorientiertes Interface Design",
    },
    {
      icon: Cloud,
      title: "Backend Integration",
      description: "Sichere Cloud-Anbindung",
    },
    {
      icon: Store,
      title: "App Store Launch",
      description: "Publikation & Marketing",
    },
    {
      icon: Zap,
      title: "Performance",
      description: "Optimierte App-Leistung",
    },
  ];

  return (
    <div className="min-h-screen pt-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-16">
        {/* Hero Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full mb-6">
            <Smartphone className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
            <span className="text-blue-600 dark:text-blue-400 font-medium">
              Mobile App Development
            </span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">
            Ihre Vision als
            <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              {" "}
              Mobile App
            </span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Von der Konzeption bis zum App Store Launch entwickeln wir Ihre
            mobile Anwendung mit modernsten Technologien
          </p>
        </div>

        {/* Platform Selection */}
        <div className="grid lg:grid-cols-3 gap-8 mb-16">
          {platforms.map((platform) => (
            <motion.button
              key={platform.id}
              onClick={() => setActivePlatform(platform.id)}
              whileHover={{ scale: 1.02 }}
              className={`
                p-8 rounded-2xl transition-all duration-300
                ${
                  activePlatform === platform.id
                    ? "bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg"
                    : "bg-white dark:bg-gray-800 hover:shadow-lg"
                }
              `}
            >
              <div className="flex flex-col items-center text-center">
                {React.createElement(platform.icon, {
                  className: `w-16 h-16 mb-6 ${
                    activePlatform === platform.id
                      ? "text-white"
                      : "text-blue-600 dark:text-blue-400"
                  }`,
                })}
                <h3
                  className={`text-2xl font-semibold mb-3 ${
                    activePlatform === platform.id
                      ? "text-white"
                      : "text-gray-900 dark:text-white"
                  }`}
                >
                  {platform.title}
                </h3>
                <p
                  className={`mb-6 ${
                    activePlatform === platform.id
                      ? "text-blue-100"
                      : "text-gray-600 dark:text-gray-300"
                  }`}
                >
                  {platform.description}
                </p>
                <div className="space-y-3 mb-6">
                  {platform.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center justify-center">
                      <Check className="w-5 h-5 mr-2" />
                      <span className="text-sm">{feature}</span>
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap justify-center gap-2">
                  {platform.tools.map((tool, idx) => (
                    <span
                      key={idx}
                      className={`px-3 py-1 rounded-full text-sm ${
                        activePlatform === platform.id
                          ? "bg-white/20 text-white"
                          : "bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400"
                      }`}
                    >
                      {tool}
                    </span>
                  ))}
                </div>
                <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
                  <span className="text-sm">Marktanteil Deutschland</span>
                  <p className="text-2xl font-bold">{platform.marketShare}</p>
                </div>
              </div>
            </motion.button>
          ))}
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-4 gap-6 mb-16">
          {services.map((service, idx) => (
            <div
              key={idx}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg flex items-center justify-center mb-4">
                {React.createElement(service.icon, {
                  className: "w-6 h-6 text-white",
                })}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-sm">
                {service.description}
              </p>
            </div>
          ))}
        </div>

        {/* Features Showcase */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              Warum Mobile-First?
            </h2>
            <div className="space-y-4">
              {[
                "Über 80% der Deutschen nutzen Smartphones täglich",
                "Mobile Commerce wächst jährlich um 12.8%",
                "Apps generieren 3.5x mehr Conversions als mobile Websites",
                "Direkter Zugang zu Smartphone-Features (Kamera, GPS, etc.)",
              ].map((point, idx) => (
                <div key={idx} className="flex items-start space-x-3">
                  <div className="flex-shrink-0 w-6 h-6 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center mt-1">
                    <Check className="w-4 h-4 text-blue-600 dark:text-blue-400" />
                  </div>
                  <span className="text-gray-600 dark:text-gray-300">
                    {point}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              Unser App-Entwicklungsprozess
            </h2>
            <div className="space-y-6">
              {[
                { step: 1, title: "Konzeption & Design", icon: Palette },
                { step: 2, title: "Entwicklung & Testing", icon: Code },
                { step: 3, title: "Deployment & Launch", icon: Rocket },
                { step: 4, title: "Wartung & Updates", icon: Settings },
              ].map((phase, idx) => (
                <div key={idx} className="flex items-center space-x-4">
                  <div className="w-8 h-8 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 font-semibold">
                    {phase.step}
                  </div>
                  <div className="flex-1 bg-white dark:bg-gray-800 rounded-lg p-4">
                    {phase.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <div className="inline-flex flex-col items-center">
            <div className="flex flex-wrap justify-center gap-4 mb-8">
              <button className="inline-flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                Projekt jetzt starten
                <ArrowRight className="ml-2 w-5 h-5" />
              </button>
              <button className="inline-flex items-center px-8 py-4 rounded-xl border-2 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 font-medium hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300 hover:-translate-y-1">
                Kostenlose Beratung
                <MessageSquare className="ml-2 w-5 h-5" />
              </button>
            </div>
            <span className="text-sm text-gray-500 dark:text-gray-400 mt-4">
              Bereits über 50+ erfolgreiche App-Projekte realisiert
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDevHero;
