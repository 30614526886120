import React from "react";
import Hero from "../components/HomeSections/Hero";
import Services from "../components/HomeSections/Services";
import Portfolio from "../components/HomeSections/Portfolio";
import PortfolioCTA from "../components/HomeSections/PortfolioCTA";
import RapidDevShowcase from "../components/HomeSections/RapidDevShowcase";
import AboutUs from "../components/HomeSections/AboutUs";
import SpecialOffer from "../components/HomeSections/SpecialOffer";

const Home = () => {
  return (
    <div>
      <Hero />
      <SpecialOffer />
      <Services />
      <PortfolioCTA />
      <RapidDevShowcase />
      <AboutUs />
    </div>
  );
};

export default Home;
