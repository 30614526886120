import React from "react";

const Imprint = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-20">
        <div className="mb-8">
          <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white">
            Impressum
          </h1>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Angaben gemäß § 5 TMG
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            TruTec
            <br />
            Goldener Spiegel 6
            <br />
            57074 Siegen
            <br />
            Deutschland
          </p>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Kontakt
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Telefon: +49 170 7238166
            <br />
            info@tru-tec.de
          </p>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Vertreten durch
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Justin Lata
            <br />
            CEO & CTO
            <br />
            info@tru-tec.de
          </p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
