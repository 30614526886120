import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Code2,
  Rocket,
  Zap,
  Users,
  Target,
  Shield,
  CheckCircle,
  ArrowRight,
  MessagesSquare,
  Terminal,
  Laptop,
  Sparkles,
} from "lucide-react";

const AboutUs = () => {
  const [hoveredFeature, setHoveredFeature] = useState(null);

  const features = [
    {
      icon: Terminal,
      title: "Technische Expertise",
      description: "Modernste Technologien und Best Practices für optimale Performance",
      gradient: "from-blue-600 to-cyan-600",
      details: [
        "React & Next.js",
        "Tailwind CSS",
        "Node.js & Express",
        "MongoDB & PostgreSQL",
      ],
    },
    {
      icon: Target,
      title: "Maßgeschneiderte Lösungen",
      description: "Individuelle Entwicklung nach Ihren spezifischen Anforderungen",
      gradient: "from-purple-600 to-pink-600",
      details: [
        "Responsive Design",
        "Cross-Platform",
        "Performance-Optimiert",
        "SEO-Friendly",
      ],
    },
    {
      icon: Shield,
      title: "Qualität & Sicherheit",
      description: "Höchste Standards für Ihre digitale Präsenz",
      gradient: "from-orange-600 to-red-600",
      details: [
        "HTTPS & SSL",
        "DSGVO-Konform",
        "Regelmäßige Updates",
        "Backup-Systeme",
      ],
    },
  ];

  const handleContactScroll = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative py-20 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div 
          className="absolute inset-0 opacity-40 dark:opacity-20"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(0 0 0 / 0.1) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />
        <div className="absolute -top-40 -right-40 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-96 h-96 bg-blue-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Header Section */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-100 to-indigo-100 dark:from-purple-900/50 dark:to-indigo-900/50 px-6 py-2 rounded-full mb-6"
          >
            <Sparkles className="w-5 h-5 text-purple-600 dark:text-purple-400" />
            <span className="text-base font-medium text-purple-900 dark:text-purple-300">
              Über Uns
            </span>
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-4xl md:text-5xl font-bold mb-6"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300">
              Wir machen
            </span>
            <span className="relative ml-2">
              <span className="relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-cyan-500 to-pink-500">
                den Unterschied
              </span>
              <span className="absolute inset-x-0 bottom-0 h-3 bg-gradient-to-r from-purple-200/30 to-pink-200/30 dark:from-purple-900/30 dark:to-pink-900/30 transform -skew-x-12" />
            </span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
          >
            Mit Leidenschaft für Innovation und einem Auge fürs Detail entwickeln
            wir digitale Lösungen, die begeistern und Ihre Ziele erreichen.
          </motion.p>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-20">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 + index * 0.1 }}
              className="relative group"
              onMouseEnter={() => setHoveredFeature(index)}
              onMouseLeave={() => setHoveredFeature(null)}
            >
              <div className="relative h-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl p-8 border border-gray-200/50 dark:border-gray-700/50 hover:border-transparent transition-colors duration-300">
                {/* Gradient Border Effect */}
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-r opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                     style={{
                       background: `linear-gradient(to right, ${feature.gradient.split(' ')[1]}, ${feature.gradient.split(' ')[3]})`,
                       mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                       maskComposite: 'exclude',
                       padding: '1px',
                     }}
                />

                {/* Icon */}
                <div className={`inline-flex p-3 rounded-xl bg-gradient-to-r ${feature.gradient} mb-6`}>
                  {React.createElement(feature.icon, {
                    className: "w-6 h-6 text-white",
                  })}
                </div>

                {/* Content */}
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                  {feature.description}
                </p>

                {/* Details List */}
                <motion.div
                  initial={false}
                  animate={{ height: hoveredFeature === index ? "auto" : 0 }}
                  className="overflow-hidden"
                >
                  <div className="space-y-3 pt-4">
                    {feature.details.map((detail, idx) => (
                      <motion.div
                        key={idx}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ 
                          opacity: hoveredFeature === index ? 1 : 0,
                          x: hoveredFeature === index ? 0 : -20 
                        }}
                        transition={{ delay: idx * 0.1 }}
                        className="flex items-center gap-2"
                      >
                        <CheckCircle className="w-5 h-5 text-green-500" />
                        <span className="text-gray-600 dark:text-gray-300">
                          {detail}
                        </span>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Stats Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
          className="grid md:grid-cols-4 gap-8 mb-20"
        >
          {[
            { icon: Users, value: "300%", label: "Teamzuwachs in 3 Monaten" },
            { icon: Zap, value: "100+", label: "Projekte Abgeschlossen" },
            { icon: Rocket, value: "3+", label: "Jahre Erfahrung" },
            { icon: Code2, value: "1M+", label: "Zeilen Code" },
          ].map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 + index * 0.1 }}
              className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl p-6 border border-gray-200/50 dark:border-gray-700/50 text-center group hover:border-transparent transition-all duration-300"
            >
              <div className="inline-flex p-3 rounded-xl bg-gradient-to-r from-purple-600 to-indigo-600 mb-4 group-hover:scale-110 transition-transform duration-300">
                {React.createElement(stat.icon, {
                  className: "w-6 h-6 text-white",
                })}
              </div>
              <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600 mb-2">
                {stat.value}
              </div>
              <div className="text-gray-600 dark:text-gray-300">
                {stat.label}
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="text-center"
        >
          <div className="inline-flex flex-col items-center bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl p-8 border border-gray-200/50 dark:border-gray-700/50">
            <MessagesSquare className="w-12 h-12 text-purple-600 dark:text-purple-400 mb-4" />
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Bereit für Ihr nächstes Projekt?
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Lassen Sie uns gemeinsam Ihre Ideen in die Realität umsetzen.
            </p>
            <button
              onClick={handleContactScroll}
              className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-xl text-lg font-medium shadow-lg shadow-purple-500/25 hover:shadow-xl hover:shadow-purple-500/30 transition-all duration-300 hover:-translate-y-0.5"
            >
              Kontakt aufnehmen
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;