import React from "react";
import { motion } from "framer-motion";
import { Heart, Lightbulb, Users, Target, Shield, Rocket } from "lucide-react";

const ValueCard = ({ icon: Icon, title, description, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay }}
      className="flex flex-col items-center p-6 rounded-xl bg-white/5 backdrop-blur-sm border border-gray-200 dark:border-gray-800 hover:border-purple-500/50 dark:hover:border-purple-500/50 transition-all duration-300"
    >
      <div className="mb-4 p-3 rounded-full bg-gradient-to-r from-pink-500/10 to-purple-500/10 dark:from-pink-500/20 dark:to-purple-500/20">
        <Icon className="w-6 h-6 text-purple-500" />
      </div>
      <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
        {title}
      </h3>
      <p className="text-center text-gray-600 dark:text-gray-300">
        {description}
      </p>
    </motion.div>
  );
};

const AboutValues = () => {
  const values = [
    {
      icon: Heart,
      title: "Leidenschaft",
      description:
        "Wir lieben was wir tun und setzen uns mit Herzblut für jedes Projekt ein.",
    },
    {
      icon: Lightbulb,
      title: "Innovation",
      description:
        "Wir denken über den Tellerrand hinaus und finden kreative Lösungen für komplexe Herausforderungen.",
    },
    {
      icon: Users,
      title: "Partnerschaft",
      description:
        "Wir sehen uns als langfristiger Partner unserer Kunden und wachsen gemeinsam.",
    },
    {
      icon: Target,
      title: "Qualität",
      description:
        "Wir setzen höchste Standards und liefern Ergebnisse, die begeistern.",
    },
    {
      icon: Shield,
      title: "Verlässlichkeit",
      description:
        "Auf uns ist Verlass - wir stehen zu unseren Zusagen und Terminen.",
    },
    {
      icon: Rocket,
      title: "Wachstum",
      description:
        "Wir entwickeln uns ständig weiter und bleiben am Puls der Zeit.",
    },
  ];

  return (
    <div className="py-24 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white">
            Unsere Werte
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Diese Grundsätze leiten unser Handeln und prägen die Zusammenarbeit
            mit unseren Kunden.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <ValueCard
              key={value.title}
              icon={value.icon}
              title={value.title}
              description={value.description}
              delay={index * 0.1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutValues;
