import React from "react";

import WebDesignHero from "../components//WebDesignSections/WebDesignHero";
import Offershowcase from "../components/Offershowcase";
import WebDevService from "../components/WebDesignSections/WebDevService";
import EcommerceProcess from "../components/WebDesignSections/EcommerceProcess";

const Webdevelopement = () => {
  return (
    <div>
      <WebDesignHero />
      <Offershowcase />
      <WebDevService />
      <EcommerceProcess />
    </div>
  );
};

export default Webdevelopement;
