import React, { useState, useEffect } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  AnimatePresence,
} from "framer-motion";
import { Heart, Lightbulb, Users, Target, Shield, Rocket } from "lucide-react";

const values = [
  {
    icon: Heart,
    title: "Leidenschaft",
    description:
      "Wir brennen für digitale Innovation. Jedes Projekt ist für uns eine Chance, Großartiges zu erschaffen und neue Maßstäbe zu setzen.",
    color: "from-pink-500 to-rose-500",
    darkColor: "from-pink-400 to-rose-400",
  },
  {
    icon: Lightbulb,
    title: "Innovation",
    description:
      "Wir denken disruptiv und gestalten die digitale Zukunft aktiv mit. Dabei setzen wir auf zukunftsweisende Technologien und kreative Lösungen.",
    color: "from-yellow-500 to-orange-500",
    darkColor: "from-yellow-400 to-orange-400",
  },
  {
    icon: Users,
    title: "Partnerschaft",
    description:
      "Echte Zusammenarbeit bedeutet für uns, gemeinsam zu wachsen und Erfolge zu feiern. Wir sind mehr als Dienstleister – wir sind Ihr digitaler Partner.",
    color: "from-blue-500 to-indigo-500",
    darkColor: "from-blue-400 to-indigo-400",
  },
  {
    icon: Target,
    title: "Qualität",
    description:
      "Perfektion liegt im Detail. Wir vereinen technische Exzellenz mit ästhetischem Design und schaffen so nachhaltige digitale Lösungen.",
    color: "from-emerald-500 to-teal-500",
    darkColor: "from-emerald-400 to-teal-400",
  },
  {
    icon: Shield,
    title: "Verlässlichkeit",
    description:
      "Vertrauen ist die Basis unserer Arbeit. Mit Transparenz, klarer Kommunikation und absoluter Termintreue machen wir jedes Projekt zum Erfolg.",
    color: "from-purple-500 to-violet-500",
    darkColor: "from-purple-400 to-violet-400",
  },
  {
    icon: Rocket,
    title: "Wachstum",
    description:
      "Stillstand ist Rückschritt. Wir entwickeln uns kontinuierlich weiter und treiben Innovation voran – für uns und unsere Kunden.",
    color: "from-cyan-500 to-blue-500",
    darkColor: "from-cyan-400 to-blue-400",
  },
];

const InteractiveValue = ({ value, isSelected, onClick, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const Icon = value.icon;

  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: 1,
        scale: isSelected ? 1 : 0.9,
        y: isSelected ? -20 : 0,
      }}
      whileHover={{ scale: isSelected ? 1 : 0.95 }}
      transition={{ duration: 0.5, type: "spring" }}
      onClick={onClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className={`
        relative cursor-pointer
        ${isSelected ? "col-span-2 row-span-2" : "col-span-1 row-span-1"}
        rounded-2xl overflow-hidden
        transition-shadow duration-500
        ${isSelected ? "shadow-2xl" : "shadow-lg hover:shadow-xl"}
      `}
    >
      <div
        className={`
        absolute inset-0 bg-gradient-to-br ${value.color} dark:${value.darkColor}
        opacity-10 dark:opacity-20
      `}
      />

      <div className="relative p-6 h-full flex flex-col items-center justify-center">
        <motion.div
          animate={{
            scale: isHovered ? 1.1 : 1,
            rotate: isHovered ? 360 : 0,
          }}
          transition={{ duration: 0.5 }}
          className={`
            p-4 rounded-full 
            bg-gradient-to-r ${value.color} dark:${value.darkColor}
            shadow-lg
          `}
        >
          <Icon className="w-8 h-8 text-white" />
        </motion.div>

        <motion.h3
          layout="position"
          className="text-xl md:text-2xl font-bold mt-4 mb-2 text-gray-900 dark:text-white text-center"
        >
          {value.title}
        </motion.h3>

        <AnimatePresence>
          {isSelected && (
            <motion.p
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="text-gray-600 dark:text-gray-300 text-center"
            >
              {value.description}
            </motion.p>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-white/20 to-transparent dark:from-white/10"
        style={{
          opacity: isHovered ? 0.2 : 0,
        }}
        transition={{ duration: 0.3 }}
      />
    </motion.div>
  );
};

const FloatingParticle = ({ color }) => {
  const randomDuration = Math.random() * 10 + 10;
  const randomDelay = Math.random() * -20;

  return (
    <motion.div
      className={`absolute w-2 h-2 rounded-full bg-gradient-to-r ${color} opacity-30`}
      animate={{
        y: [-20, -40, -20],
        x: [-10, 10, -10],
        scale: [1, 1.2, 1],
      }}
      transition={{
        duration: randomDuration,
        delay: randomDelay,
        repeat: Infinity,
        ease: "linear",
      }}
      style={{
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
      }}
    />
  );
};

const AboutValues = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { scrollYProgress } = useScroll();

  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  const springConfig = { stiffness: 100, damping: 30, restDelta: 0.001 };
  const spring = useSpring(scrollYProgress, springConfig);

  return (
    <div className="relative min-h-screen py-24 overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800"
        style={{ y: backgroundY }}
      />

      {/* Floating particles background */}
      {values.map((value, i) =>
        Array.from({ length: 3 }).map((_, j) => (
          <FloatingParticle key={`particle-${i}-${j}`} color={value.color} />
        ))
      )}

      <div className="max-w-7xl mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-gray-900 dark:text-white">
            Unsere Werte
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Diese Prinzipien definieren, wer wir sind und wie wir arbeiten.
          </p>
        </motion.div>

        <motion.div
          className="grid grid-cols-2 md:grid-cols-3 gap-6 relative"
          style={{ opacity }}
        >
          {values.map((value, index) => (
            <InteractiveValue
              key={value.title}
              value={value}
              index={index}
              isSelected={selectedValue === index}
              onClick={() =>
                setSelectedValue(selectedValue === index ? null : index)
              }
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default AboutValues;
