import React from "react";

const DatenschutzErklaerung = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-20">
        <div className="mb-8">
          <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white">
            Datenschutzerklärung
          </h1>
        </div>
        <div className="mb-12">
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Wir legen größten Wert auf den Schutz Ihrer Privatsphäre. Diese
            Datenschutzerklärung informiert Sie darüber, wie wir mit Daten auf
            unserer Website umgehen.
          </p>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Datenerhebung und -verwendung
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Wir verwenden ausschließlich Vercel Analytics, um die Nutzung
            unserer Website zu verstehen und zu verbessern. Dabei werden keine
            personenbezogenen Daten erfasst oder gespeichert.
          </p>
          <p className="text-gray-600 dark:text-gray-400">
            Die gesammelten Analysedaten sind anonym und umfassen lediglich:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-600 dark:text-gray-400">
            <li>Seitenaufrufe</li>
            <li>Besuchsdauer</li>
            <li>Gerätetyp (mobil/desktop)</li>
            <li>Ungefähre geografische Region</li>
          </ul>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Cookies
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Unsere Website verwendet keine Cookies für Tracking oder
            Analysezwecke. Vercel Analytics arbeitet ohne Cookies und ohne die
            Speicherung personenbezogener Daten.
          </p>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Ihre Rechte
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Da wir keine personenbezogenen Daten erheben, speichern oder
            verarbeiten, entstehen keine personenbezogenen Datensätze, die
            eingesehen, korrigiert oder gelöscht werden müssten.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Kontakt für Datenschutzfragen
          </h2>
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            Bei Fragen zu unserer Datenschutzerklärung können Sie uns jederzeit
            kontaktieren:
          </p>
          <p className="text-gray-600 dark:text-gray-400">info@tru-tec.de</p>
        </div>
        <div className="mt-12">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Stand: November 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default DatenschutzErklaerung;
