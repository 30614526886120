import React, { useState, useEffect, useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from "framer-motion";
import {
  Users,
  Code,
  Target,
  Rocket,
  Palette,
  Zap,
  Brain,
  Globe,
  Star,
  Award,
  Heart,
  Sparkles,
} from "lucide-react";

const AboutTeamSection = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [hoveredValue, setHoveredValue] = useState(null);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, margin: "-100px" });

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 0.5], [0.8, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);
  const springScale = useSpring(scale, {
    mass: 0.5,
    stiffness: 100,
    damping: 10,
  });

  // Sync with system/document dark mode
  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const teamValues = [
    {
      title: "Innovation",
      description:
        "Wir denken in Lösungen und gestalten die digitale Zukunft aktiv mit",
      icon: Brain,
      gradient: "from-pink-500 to-rose-500",
      stats: ["50+ Innovationen", "12 Patente", "3 Forschungsprojekte"],
    },
    {
      title: "Excellence",
      description:
        "Kompromisslose Qualität und messbare Resultate für unsere Kunden",
      icon: Award,
      gradient: "from-blue-500 to-cyan-500",
      stats: ["99% Kundenzufriedenheit", "15+ Awards", "100+ Projekte"],
    },
    {
      title: "Kreativität",
      description:
        "Mutige Ideen und einzigartige Lösungen für komplexe Herausforderungen",
      icon: Sparkles,
      gradient: "from-purple-500 to-violet-500",
      stats: ["200+ Designs", "40+ Branchen", "∞ Inspiration"],
    },
    {
      title: "Passion",
      description: "Mit Herzblut und Engagement für den Erfolg unserer Kunden",
      icon: Heart,
      gradient: "from-amber-500 to-orange-500",
      stats: ["24/7 Support", "98% Mitarbeiterzufriedenheit", "5★ Rating"],
    },
  ];

  const achievements = [
    { value: 100, label: "Projekte", prefix: "+" },
    { value: 25, label: "Experten", prefix: "" },
    { value: 15, label: "Jahre", prefix: "+" },
    { value: 99, label: "% Zufriedenheit", prefix: "" },
  ];

  // Animated counter component with spring physics
  const Counter = ({ value, duration = 2, prefix = "" }) => {
    const [count, setCount] = useState(0);
    const springValue = useSpring(0, {
      duration: duration * 1000,
      onUpdate: (latest) => setCount(Math.floor(latest)),
    });

    useEffect(() => {
      if (isInView) {
        springValue.set(value);
      }
    }, [value, springValue, isInView]);

    return (
      <span>
        {count}
        {prefix}
      </span>
    );
  };

  return (
    <section
      ref={sectionRef}
      className={`relative py-32 overflow-hidden ${
        isDarkMode ? "bg-gray-900" : "bg-gray-50"
      } transition-colors duration-300`}
    >
      {/* Animated background patterns */}
      <motion.div
        className="absolute inset-0 opacity-30"
        style={{ opacity: opacity }}
      >
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_80%,rgba(255,182,255,0.1),rgba(255,182,255,0)_50%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_20%,rgba(120,119,198,0.1),rgba(255,182,255,0)_50%)]" />
      </motion.div>

      <div className="relative max-w-7xl mx-auto px-4">
        {/* Header Section */}
        <motion.div
          className="text-center mb-20"
          style={{ scale: springScale, opacity }}
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            className="inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-purple-500/10 to-pink-500/10 dark:from-purple-500/20 dark:to-pink-500/20 mb-6"
          >
            <Star className="w-5 h-5 text-purple-500 mr-2" />
            <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
              Unser Team • Unsere Werte
            </span>
          </motion.div>

          <h2
            className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            <span className="block">Experten mit </span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-rose-500">
              Leidenschaft & Vision
            </span>
          </h2>

          <p
            className={`text-lg max-w-3xl mx-auto ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Unser diverses Team vereint kreative Köpfe und technische Experten.
            Gemeinsam schaffen wir digitale Lösungen, die begeistern und
            Mehrwert stiften.
          </p>
        </motion.div>

        {/* Stats Grid */}
        <motion.div
          className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-20"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {achievements.map((stat, idx) => (
            <motion.div
              key={idx}
              className={`relative group p-6 rounded-xl ${
                isDarkMode
                  ? "bg-gray-800/50 hover:bg-gray-800"
                  : "bg-white/50 hover:bg-white"
              } backdrop-blur-sm border border-gray-200/20 transition-all duration-300`}
              whileHover={{ scale: 1.02, rotate: 1 }}
            >
              <motion.div
                className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                initial={false}
              />
              <div className="relative">
                <h3
                  className={`text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500`}
                >
                  <Counter value={stat.value} prefix={stat.prefix} />
                </h3>
                <p
                  className={`${
                    isDarkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {stat.label}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Values Grid */}
        <div className="grid md:grid-cols-2 gap-8">
          {teamValues.map((value, idx) => (
            <motion.div
              key={idx}
              className={`relative p-8 rounded-2xl ${
                isDarkMode
                  ? "bg-gray-800/50 hover:bg-gray-800"
                  : "bg-white/50 hover:bg-white"
              } backdrop-blur-sm border border-gray-200/20 transition-all duration-300 overflow-hidden`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: idx * 0.1 }}
              onHoverStart={() => setHoveredValue(idx)}
              onHoverEnd={() => setHoveredValue(null)}
              whileHover={{ scale: 1.02 }}
            >
              <motion.div
                className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                initial={false}
              />
              <div className="relative z-10">
                <div
                  className={`bg-gradient-to-br ${value.gradient} w-16 h-16 rounded-xl flex items-center justify-center mb-6`}
                >
                  <value.icon className="w-8 h-8 text-white" />
                </div>
                <h3
                  className={`text-2xl font-bold mb-4 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {value.title}
                </h3>
                <p
                  className={`mb-6 ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  {value.description}
                </p>
                <motion.div
                  className="space-y-2"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{
                    opacity: hoveredValue === idx ? 1 : 0,
                    height: hoveredValue === idx ? "auto" : 0,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {value.stats.map((stat, statIdx) => (
                    <motion.div
                      key={statIdx}
                      className="flex items-center space-x-2"
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: statIdx * 0.1 }}
                    >
                      <Zap
                        className={`w-4 h-4 ${
                          isDarkMode ? "text-purple-400" : "text-purple-500"
                        }`}
                      />
                      <span
                        className={`text-sm ${
                          isDarkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                      >
                        {stat}
                      </span>
                    </motion.div>
                  ))}
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutTeamSection;
