import React from "react";

import HeroPortfolio from "../components/PortfolioSections/HeroPortfolio";
import PortfolioProjects from "../components/PortfolioSections/PortfolioProjects";

const About = () => {
  return (
    <div>
      <HeroPortfolio />
      <PortfolioProjects />
    </div>
  );
};

export default About;
