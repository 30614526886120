import React, { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Rocket,
  ArrowRight,
  MessageCircle,
  Calendar,
  Sparkles,
  PhoneCall,
} from "lucide-react";

const PortfolioCTA = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-100px" });
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleContactScroll = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const ctaCards = [
    {
      id: "consultation",
      icon: MessageCircle,
      title: "Freie Beratung",
      description: "30 minütige strategische Beratung mit unseren Experten",
      color: "from-blue-600 to-indigo-600",
      shadowColor: "blue",
      action: "Meeting vereinbaren",
      onClick: handleContactScroll
    },
    {
      id: "discovery",
      icon: Rocket,
      title: "Projekt starten",
      description: "Transformier die Vision in Realität",
      color: "from-violet-600 to-purple-600",
      shadowColor: "violet",
      action: "Jetzt starten",
      onClick: handleContactScroll,
      primary: true,
    },
    {
      id: "demo",
      icon: Calendar,
      title: "Produkt Showcase",
      description: "Sieh unsere Lösung in aktion",
      color: "from-pink-600 to-rose-600",
      shadowColor: "pink",
      action: "Portfolio erkunden",
      onClick: () => navigate("/portfolio")
    },
  ];

  return (
    <div ref={containerRef} className="relative mt-32 mb-20">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000" />
        <div className="absolute -bottom-8 left-1/3 w-96 h-96 bg-pink-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000" />
      </div>

      {/* Main Content Container */}
      <div className="relative">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
          className="text-center max-w-3xl mx-auto px-4 mb-16"
        >
          <div className="inline-flex items-center space-x-2 bg-white/90 dark:bg-gray-800/90 rounded-full px-4 py-2 mb-6 backdrop-blur-sm border border-gray-200 dark:border-gray-700">
            <Sparkles className="w-5 h-5 text-blue-600" />
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              Transform Your Digital Presence
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300">
              Bereit für
            </span>
            <span className="relative ml-2">
              <span className="relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600">
                etwas großartiges?
              </span>
              <span className="absolute inset-x-0 bottom-0 h-3 bg-blue-200/30 dark:bg-blue-900/30 transform -skew-x-12" />
            </span>
          </h2>

          <p className="text-lg text-gray-600 dark:text-gray-300">
            Werde Teil der führeden Industriellen Bewegung des Jahrhunderts, und
            lerne die Zukunft mit unseren Experten kennen.
          </p>
        </motion.div>

        {/* CTA Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto px-4">
          {ctaCards.map((card, index) => {
            const IconComponent = card.icon;
            const isHovered = hoveredButton === card.id;

            return (
              <motion.div
                key={card.id}
                initial={{ opacity: 0, y: 30 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                onHoverStart={() => setHoveredButton(card.id)}
                onHoverEnd={() => setHoveredButton(null)}
                className={`relative group ${card.primary ? "md:-mt-4" : ""}`}
              >
                {/* Card Container */}
                <div
                  className={`
                  relative h-full rounded-2xl transition-all duration-300
                  ${card.primary ? "scale-105" : "group-hover:scale-105"}
                `}
                >
                  {/* Gradient Border */}
                  <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-transparent to-transparent group-hover:from-blue-500/20 group-hover:to-purple-500/20 transition-all duration-300" />

                  {/* Card Content */}
                  <div className="relative p-8 h-full bg-white dark:bg-gray-800 rounded-2xl border border-gray-100 dark:border-gray-700 group-hover:border-transparent transition-colors duration-300">
                    {/* Icon */}
                    <motion.div
                      animate={
                        isHovered
                          ? { scale: 1.1, rotate: 5 }
                          : { scale: 1, rotate: 0 }
                      }
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                      className={`
                        inline-flex p-3 rounded-xl bg-gradient-to-br ${card.color}
                        ${card.primary ? "p-4" : ""}
                      `}
                    >
                      <IconComponent
                        className={`
                        text-white
                        ${card.primary ? "w-7 h-7" : "w-6 h-6"}
                      `}
                      />
                    </motion.div>

                    {/* Content */}
                    <div className="mt-6 space-y-4">
                      <h3
                        className={`
                        text-xl font-bold text-gray-900 dark:text-white
                        ${card.primary ? "text-2xl" : ""}
                      `}
                      >
                        {card.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        {card.description}
                      </p>
                    </div>

                    {/* Action Button */}
                    <motion.button
                      onClick={card.onClick}
                      initial={false}
                      animate={isHovered ? { y: -2 } : { y: 0 }}
                      className={`
                        mt-8 w-full inline-flex items-center justify-center space-x-2 px-6 py-3 rounded-xl
                        font-medium transition-all duration-200 cursor-pointer
                        ${
                          card.primary
                            ? "bg-gradient-to-r from-blue-600 to-violet-600 text-white shadow-lg shadow-blue-500/30 hover:shadow-xl hover:shadow-blue-500/40"
                            : "bg-gray-50 dark:bg-gray-700/50 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                        }
                      `}
                    >
                      <span>{card.action}</span>
                      <ArrowRight
                        className={`w-5 h-5 transition-transform duration-200 ${
                          isHovered ? "translate-x-1" : ""
                        }`}
                      />
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* Quick Contact Section */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="mt-12 text-center"
        >
          <div className="inline-flex items-center space-x-3 bg-gray-50 dark:bg-gray-800/50 rounded-full px-6 py-3 text-gray-600 dark:text-gray-400">
            <PhoneCall className="w-5 h-5" />
            <span>Kurz Zeit? Ruf einfach an</span>
            <a
              href="tel:+491707238166"
              className="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
            >
              (+49) 170 7238166
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PortfolioCTA;