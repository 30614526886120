import React from "react";
import { motion } from "framer-motion";
import {
  ShoppingCart,
  Store,
  Truck,
  CreditCard,
  Package,
  Smartphone,
  Search,
  BarChart,
  Users,
  Shield,
  Zap,
  Gift,
  Tags,
  Repeat,
  Speech,
  Star,
  ShoppingBag,
  PieChart,
  Wallet,
  Boxes,
  Settings,
} from "lucide-react";

const EcommerceProcess = () => {
  const processes = [
    {
      phase: "Shop-Analyse",
      icon: Store,
      title: "E-Commerce Strategie",
      description:
        "Entwicklung einer maßgeschneiderten E-Commerce-Strategie basierend auf Marktanalyse und Zielgruppendefinition.",
      metrics: [
        { value: "47%", label: "Ø Conversion-Steigerung" },
        { value: "+85%", label: "Warenkorbwert" },
        { value: "2.4x", label: "Wiederkehrende Kunden" },
      ],
      features: [
        {
          icon: Search,
          title: "Marktanalyse",
          description:
            "Detaillierte Wettbewerbsanalyse und Marktpositionierung",
          color: "text-blue-500",
        },
        {
          icon: Users,
          title: "Zielgruppen",
          description: "Präzise Zielgruppendefinition und Personas",
          color: "text-blue-600",
        },
        {
          icon: PieChart,
          title: "Potentialanalyse",
          description: "Umsatzpotential und Wachstumsprognosen",
          color: "text-blue-700",
        },
      ],
    },
    {
      phase: "Shop-Konzeption",
      icon: ShoppingBag,
      title: "UX & Design",
      description:
        "Entwicklung eines optimalen Einkaufserlebnisses mit Fokus auf Conversion und Benutzerfreundlichkeit.",
      metrics: [
        { value: "-35%", label: "Abbruchquote" },
        { value: "+92%", label: "Mobile Conversion" },
        { value: "< 1.2s", label: "Ladezeit" },
      ],
      features: [
        {
          icon: Smartphone,
          title: "Mobile First",
          description: "Optimierte Mobile Experience & PWA",
          color: "text-indigo-500",
        },
        {
          icon: Zap,
          title: "Performance",
          description: "Maximale Geschwindigkeit & Usability",
          color: "text-indigo-600",
        },
        {
          icon: Speech,
          title: "User Testing",
          description: "A/B Tests & Nutzer-Feedback",
          color: "text-indigo-700",
        },
      ],
    },
    {
      phase: "Shop-System",
      icon: Settings,
      title: "Technische Umsetzung",
      description:
        "Integration aller notwendigen E-Commerce Funktionen und Schnittstellen für reibungslose Abläufe.",
      metrics: [
        { value: "100%", label: "ERP-Integration" },
        { value: "Multi", label: "Payment-Optionen" },
        { value: "Real-time", label: "Tracking" },
      ],
      features: [
        {
          icon: CreditCard,
          title: "Payment",
          description: "Alle relevanten Zahlungsarten & PSP",
          color: "text-violet-500",
        },
        {
          icon: Boxes,
          title: "Warenwirtschaft",
          description: "Nahtlose ERP & PIM Integration",
          color: "text-violet-600",
        },
        {
          icon: Shield,
          title: "Sicherheit",
          description: "SSL, 2FA & Fraud Protection",
          color: "text-violet-700",
        },
      ],
    },
    {
      phase: "Marketing",
      icon: Tags,
      title: "Marketing & SEO",
      description:
        "Optimierung für maximale Sichtbarkeit und Conversion durch verschiedene Marketing-Kanäle.",
      metrics: [
        { value: "Top 3", label: "Google Rankings" },
        { value: "24/7", label: "Marketing Tools" },
        { value: "+125%", label: "Organischer Traffic" },
      ],
      features: [
        {
          icon: BarChart,
          title: "Analytics",
          description: "Umfassendes Tracking & Reporting",
          color: "text-purple-500",
        },
        {
          icon: Gift,
          title: "Kampagnen",
          description: "Marketing-Automation & CRM",
          color: "text-purple-600",
        },
        {
          icon: Repeat,
          title: "Retargeting",
          description: "Multi-Channel Remarketing",
          color: "text-purple-700",
        },
      ],
    },
  ];

  return (
    <div className="w-full bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-20">
        {/* Header */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="flex items-center justify-center gap-4 mb-6"
          >
            <ShoppingCart className="w-8 h-8 text-purple-600" />
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white">
              E-Commerce Excellence
            </h2>
          </motion.div>
          <motion.p
            className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Von der Konzeption bis zum erfolgreichen Online-Shop
          </motion.p>
        </div>

        {/* Success Stats */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-20"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          {[
            { icon: Star, value: "500+", label: "Erfolgreiche Shops" },
            { icon: Wallet, value: "2.4M€", label: "Ø Jahresumsatz" },
            { icon: Users, value: "1.2M+", label: "Aktive Shopkunden" },
            { icon: Truck, value: "98%", label: "Liefertreue" },
          ].map((stat, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-2xl p-6 text-center shadow-lg"
            >
              {React.createElement(stat.icon, {
                className: "w-8 h-8 mx-auto mb-4 text-purple-600",
              })}
              <div className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
                {stat.value}
              </div>
              <div className="text-gray-600 dark:text-gray-400">
                {stat.label}
              </div>
            </div>
          ))}
        </motion.div>

        {/* Process Steps */}
        <div className="space-y-24">
          {processes.map((process, index) => (
            <motion.div
              key={process.phase}
              className="relative"
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.7, delay: index * 0.1 }}
            >
              {/* Connection Line */}
              {index < processes.length - 1 && (
                <div className="absolute h-24 w-px bg-gradient-to-b from-purple-500 to-indigo-500 left-1/2 -bottom-24 transform -translate-x-1/2" />
              )}

              {/* Process Content */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                {/* Left Column - Info */}
                <div className="space-y-8">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 rounded-2xl bg-gradient-to-r from-purple-500 to-indigo-500">
                      {React.createElement(process.icon, {
                        className: "w-8 h-8 text-white",
                      })}
                    </div>
                    <div>
                      <div className="text-sm font-medium text-purple-600 dark:text-purple-400">
                        {process.phase}
                      </div>
                      <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                        {process.title}
                      </h3>
                    </div>
                  </div>

                  <p className="text-gray-600 dark:text-gray-300 text-lg">
                    {process.description}
                  </p>

                  {/* Metrics */}
                  <div className="grid grid-cols-3 gap-6">
                    {process.metrics.map((metric, i) => (
                      <div key={i} className="text-center">
                        <div className="text-2xl font-bold text-purple-600 dark:text-purple-400">
                          {metric.value}
                        </div>
                        <div className="text-sm text-gray-600 dark:text-gray-400">
                          {metric.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Right Column - Features */}
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8">
                  <div className="space-y-6">
                    {process.features.map((feature, i) => (
                      <motion.div
                        key={i}
                        className="flex items-start space-x-4"
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: i * 0.2 }}
                      >
                        <div className="p-2 rounded-lg bg-purple-50 dark:bg-gray-700">
                          {React.createElement(feature.icon, {
                            className: `w-6 h-6 ${feature.color}`,
                          })}
                        </div>
                        <div>
                          <h4 className="font-semibold text-gray-900 dark:text-white">
                            {feature.title}
                          </h4>
                          <p className="text-gray-600 dark:text-gray-400">
                            {feature.description}
                          </p>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          className="mt-24 text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.5 }}
        >
          <div className="inline-block p-1 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full">
            <button className="group px-8 py-4 bg-white dark:bg-gray-900 rounded-full transition-all duration-300 hover:bg-transparent">
              <span className="bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent font-semibold text-lg group-hover:text-white">
                Jetzt E-Commerce Beratung anfragen
              </span>
            </button>
          </div>
          <p className="mt-4 text-gray-600 dark:text-gray-400">
            Kostenlose Erstberatung für Ihren Online-Shop
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default EcommerceProcess;
