import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  BarChart,
  Layout,
  Code,
  Search,
  Rocket,
  Zap,
  Users,
  Clock,
  Target,
  Shield,
  Gauge,
  Award,
  Trophy,
  ArrowRight,
} from "lucide-react";

const ProcessTimeline = () => {
  const [activePhase, setActivePhase] = useState(0);
  const [progress, setProgress] = useState(0);

  const phases = [
    {
      title: "Strategische Analyse",
      dayLabel: "Tag 1",
      icon: BarChart,
      color: "from-blue-500 to-blue-600",
      insights: [
        {
          icon: Target,
          title: "98% Präzision",
          detail: "Zielgenaue Anforderungsanalyse",
          color: "text-blue-500",
        },
        {
          icon: Clock,
          title: "4h Konzeption",
          detail: "Effiziente Planungsphase",
          color: "text-blue-600",
        },
        {
          icon: Shield,
          title: "100% Transparenz",
          detail: "Vollständige Kostenkontrolle",
          color: "text-blue-700",
        },
      ],
    },
    {
      title: "Design & UX",
      dayLabel: "Tag 2-3",
      icon: Layout,
      color: "from-blue-600 to-indigo-600",
      insights: [
        {
          icon: Users,
          title: "+127% Conversion",
          detail: "Nutzerorientiertes Design",
          color: "text-indigo-500",
        },
        {
          icon: Gauge,
          title: "3x schneller",
          detail: "Optimierte User Flows",
          color: "text-indigo-600",
        },
        {
          icon: Layout,
          title: "100% Responsive",
          detail: "Perfekt auf allen Geräten",
          color: "text-indigo-700",
        },
      ],
    },
    {
      title: "Entwicklung",
      dayLabel: "Tag 4-5",
      icon: Code,
      color: "from-indigo-600 to-violet-600",
      insights: [
        {
          icon: Zap,
          title: "0.8s Ladezeit",
          detail: "Optimierte Performance",
          color: "text-violet-500",
        },
        {
          icon: Shield,
          title: "100% Getestet",
          detail: "Umfassende Code-Coverage",
          color: "text-violet-600",
        },
        {
          icon: Award,
          title: "A+ Rating",
          detail: "Beste Performance-Werte",
          color: "text-violet-700",
        },
      ],
    },
    {
      title: "Optimierung",
      dayLabel: "Tag 6",
      icon: Search,
      color: "from-violet-600 to-purple-600",
      insights: [
        {
          icon: Trophy,
          title: "Top 3 Ranking",
          detail: "Optimales SEO-Setup",
          color: "text-purple-500",
        },
        {
          icon: Target,
          title: "95% SEO-Score",
          detail: "Technische Optimierung",
          color: "text-purple-600",
        },
        {
          icon: Gauge,
          title: "100% Optimiert",
          detail: "Maximale Performance",
          color: "text-purple-700",
        },
      ],
    },
    {
      title: "Launch",
      dayLabel: "Tag 7",
      icon: Rocket,
      color: "from-purple-600 to-blue-600",
      insights: [
        {
          icon: Rocket,
          title: "7 Tage",
          detail: "Schnelle Umsetzung",
          color: "text-blue-500",
        },
        {
          icon: Shield,
          title: "24/7 Support",
          detail: "Permanente Betreuung",
          color: "text-blue-600",
        },
        {
          icon: Award,
          title: "100% Erfolg",
          detail: "Garantierte Resultate",
          color: "text-blue-700",
        },
      ],
    },
  ];

  useEffect(() => {
    // Update progress every 50ms
    const interval = setInterval(() => {
      setProgress((currentProgress) => {
        if (currentProgress >= 100) {
          // Move to next phase
          setActivePhase((current) => (current + 1) % phases.length);
          return 0;
        }
        return currentProgress + 0.5;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [phases.length]);

  const handlePhaseClick = (index) => {
    setActivePhase(index);
    setProgress(0);
  };

  // Calculate total progress including all previous phases
  const totalProgress = ((activePhase + progress / 100) / phases.length) * 100;

  return (
    <div className="w-full bg-white dark:bg-gray-900">
      <div className="max-w-5xl mx-auto px-4 py-12 sm:py-16">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-3">
            7-Tage Entwicklungsprozess
          </h2>
          <p className="text-gray-600 dark:text-gray-300 text-sm sm:text-base">
            Von der Konzeption bis zum erfolgreichen Launch
          </p>
        </div>

        {/* Timeline */}
        <div className="relative mb-24">
          {/* Progress Bar */}
          <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
            <motion.div
              className={`h-full bg-gradient-to-r ${phases[activePhase].color}`}
              style={{
                width: `${totalProgress}%`,
              }}
              transition={{
                duration: 0.05,
                ease: "linear",
              }}
            />
          </div>

          {/* Phase Markers */}
          <div className="absolute top-0 left-0 w-full flex justify-between transform -translate-y-1/2">
            {phases.map((phase, index) => {
              const showLabel =
                index === 0 ||
                index === phases.length - 1 ||
                index === activePhase;

              return (
                <motion.button
                  key={index}
                  onClick={() => handlePhaseClick(index)}
                  className="group relative"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <motion.div
                    className={`
                      w-6 h-6 rounded-full flex items-center justify-center
                      ${
                        index <= activePhase
                          ? `bg-gradient-to-r ${phase.color} shadow-lg`
                          : "bg-gray-200 dark:bg-gray-700"
                      }
                    `}
                    animate={{
                      scale: index === activePhase ? [1, 1.1, 1] : 1,
                    }}
                  >
                    {React.createElement(phase.icon, {
                      className: `w-3 h-3 ${
                        index <= activePhase ? "text-white" : "text-gray-400"
                      }`,
                    })}
                  </motion.div>

                  <AnimatePresence>
                    {showLabel && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className={`
                          absolute top-8 text-xs sm:text-sm font-medium whitespace-nowrap
                          ${
                            index === activePhase
                              ? "text-gray-900 dark:text-white"
                              : "text-gray-500 dark:text-gray-400"
                          }
                          ${
                            index === 0
                              ? "left-0"
                              : index === phases.length - 1
                              ? "right-0"
                              : "left-1/2 -translate-x-1/2"
                          }
                        `}
                      >
                        {index === 0
                          ? "Tag 1"
                          : index === phases.length - 1
                          ? "Tag 7"
                          : phase.dayLabel}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.button>
              );
            })}
          </div>
        </div>

        {/* Insights */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <AnimatePresence mode="wait">
            {phases[activePhase].insights.map((insight, index) => (
              <motion.div
                key={`${activePhase}-${index}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="flex items-start space-x-4"
              >
                <motion.div
                  className={`p-2 rounded-lg ${insight.color} bg-opacity-10`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {React.createElement(insight.icon, {
                    className: `w-6 h-6 ${insight.color}`,
                  })}
                </motion.div>
                <div>
                  <div className="font-semibold text-gray-900 dark:text-white">
                    {insight.title}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {insight.detail}
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <button className="group inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-600 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-0.5">
              <span>Jetzt Projekt starten</span>
              <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </button>
            <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
              Kostenloses Erstgespräch vereinbaren
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ProcessTimeline;
