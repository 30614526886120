import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowRight,
  ArrowLeft,
  Globe,
  ShoppingCart,
  Smartphone,
  Briefcase,
  Users,
  Rocket,
  Lightbulb,
  CheckCircle,
  ArrowUpRight,
} from "lucide-react";

const ProjectVisionHero = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({
    serviceType: "",
    scope: "",
    goal: "",
  });
  const [isCompleting, setIsCompleting] = useState(false);

  const steps = [
    {
      question: "Was möchten Sie umsetzen?",
      subtext: "Wählen Sie die Art Ihres digitalen Projekts",
      icon: (
        <Lightbulb
          className={`w-6 h-6 ${
            isDarkMode ? "text-gray-200" : "text-gray-900"
          }`}
        />
      ),
      options: [
        {
          value: "website",
          label: "Unternehmenswebsite",
          description: "Professionelle Webpräsenz für Ihr Unternehmen",
          icon: <Globe className="w-5 h-5" />,
          path: "/vision/website",
        },
        {
          value: "shop",
          label: "Online-Shop",
          description: "Verkaufen Sie Ihre Produkte im Internet",
          icon: <ShoppingCart className="w-5 h-5" />,
          path: "/vision/shop",
        },
        {
          value: "app",
          label: "Mobile App",
          description: "Native App für iOS und Android",
          icon: <Smartphone className="w-5 h-5" />,
          path: "/vision/app",
        },
        {
          value: "portal",
          label: "Kundenportal / Dashboard",
          description: "Digitale Plattform für Ihre Kunden",
          icon: <Briefcase className="w-5 h-5" />,
          path: "/vision/portal",
        },
      ],
    },
    {
      getQuestion: (prevAnswer) => {
        switch (prevAnswer) {
          case "website":
            return {
              question: "Für wen ist die Website gedacht?",
              subtext: "Definieren Sie die Größe Ihres Projekts",
              icon: (
                <Users
                  className={`w-6 h-6 ${
                    isDarkMode ? "text-gray-200" : "text-gray-900"
                  }`}
                />
              ),
              options: [
                {
                  value: "small",
                  label: "Kleine Firma / Selbstständig",
                  description:
                    "Bis zu 5 Seiten, Kontaktformular, responsive Design",
                  path: "/vision/website/small",
                },
                {
                  value: "medium",
                  label: "Mittleres Unternehmen",
                  description:
                    "Bis zu 15 Seiten, Blog, Newsletter, mehrsprachig",
                  path: "/vision/website/medium",
                },
                {
                  value: "large",
                  label: "Großunternehmen",
                  description:
                    "Unbegrenzte Seiten, komplexe Funktionen, individuelle Anforderungen",
                  path: "/vision/website/large",
                },
              ],
            };
          default:
            return null;
        }
      },
    },
    {
      getQuestion: (serviceType, scope) => ({
        question: "Was ist Ihr wichtigstes Ziel?",
        subtext: "Definieren Sie Ihre Kernziele",
        icon: (
          <Rocket
            className={`w-6 h-6 ${
              isDarkMode ? "text-gray-200" : "text-gray-900"
            }`}
          />
        ),
        options: [
          {
            value: "growth",
            label: "Wachstum & Neukunden",
            description: "Mehr Reichweite und neue Kunden gewinnen",
            path: "/vision/growth",
          },
          {
            value: "automation",
            label: "Prozesse optimieren",
            description: "Abläufe digitalisieren und effizienter gestalten",
            path: "/vision/automation",
          },
          {
            value: "service",
            label: "Kundenservice verbessern",
            description: "Besserer Service durch digitale Lösungen",
            path: "/vision/service",
          },
        ],
      }),
    },
  ];

  const getCurrentQuestion = () => {
    if (currentStep === 0) return steps[0];
    if (currentStep === 1) return steps[1].getQuestion(answers.serviceType);
    return steps[2].getQuestion(answers.serviceType, answers.scope);
  };

  const handleOptionSelect = (value, path) => {
    const field =
      currentStep === 0 ? "serviceType" : currentStep === 1 ? "scope" : "goal";
    setAnswers((prev) => ({ ...prev, [field]: value }));

    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setIsCompleting(true);
      setTimeout(() => {
        navigate(path, { state: { answers: { ...answers, [field]: value } } });
      }, 1000);
    }
  };

  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const currentQuestion = getCurrentQuestion();

  return (
    <div
      className={`min-h-screen bg-gradient-to-b transition-all duration-500 ${
        isDarkMode ? "from-gray-900 to-gray-800" : "from-gray-50 to-white"
      }`}
    >
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto text-center mb-12">
          <h1
            className={`text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r ${
              isDarkMode
                ? "from-blue-400 to-violet-400"
                : "from-blue-600 to-violet-600"
            }`}
          >
            Ihr Projekt startet hier
          </h1>
          <p
            className={`text-xl ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            In nur drei Schritten zu Ihrer maßgeschneiderten Lösung
          </p>
        </div>

        <div
          className={`max-w-2xl mx-auto rounded-xl shadow-lg transition-all duration-500 transform ${
            isCompleting ? "scale-105" : ""
          } ${
            isDarkMode
              ? "bg-gray-800 border border-gray-700 shadow-gray-900/50"
              : "bg-white border border-gray-100 shadow-gray-200/50"
          } p-6`}
        >
          {/* Progress Bar */}
          <div className="flex justify-between items-center mb-8">
            <button
              onClick={goBack}
              className={`flex items-center space-x-2 transition-all duration-300 
                ${currentStep === 0 ? "opacity-0" : "opacity-100"} 
                ${
                  isDarkMode
                    ? "text-gray-400 hover:text-white"
                    : "text-gray-600 hover:text-gray-900"
                }`}
            >
              <ArrowLeft className="w-4 h-4" />
              <span>Zurück</span>
            </button>
            <div className="flex space-x-3">
              {[0, 1, 2].map((step) => (
                <div
                  key={step}
                  className={`h-2 rounded-full transition-all duration-500 ${
                    step === currentStep
                      ? "w-8 bg-gradient-to-r from-blue-500 to-violet-500"
                      : step < currentStep
                      ? "w-4 bg-gradient-to-r from-green-400 to-green-500"
                      : `w-4 ${isDarkMode ? "bg-gray-700" : "bg-gray-200"}`
                  }`}
                />
              ))}
            </div>
          </div>

          {/* Question Section */}
          <div className="text-center mb-8">
            <div
              className={`inline-flex items-center justify-center p-3 rounded-full mb-4 transition-colors duration-300 ${
                isDarkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
            >
              {currentQuestion.icon}
            </div>
            <h2
              className={`text-2xl font-semibold mb-2 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              {currentQuestion.question}
            </h2>
            <p
              className={`text-sm ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              {currentQuestion.subtext}
            </p>
          </div>

          {/* Options */}
          <div className="grid gap-4">
            {currentQuestion.options.map((option) => (
              <button
                key={option.value}
                onClick={() => handleOptionSelect(option.value, option.path)}
                className={`group text-left p-4 rounded-lg border transition-all duration-300 transform hover:scale-102 ${
                  isDarkMode
                    ? "border-gray-700 hover:border-blue-500 hover:bg-gray-700/50"
                    : "border-gray-200 hover:border-blue-500 hover:bg-blue-50"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center space-x-3">
                    <span
                      className={`transition-colors duration-300 bg-gradient-to-r p-2 rounded-lg ${
                        isDarkMode
                          ? "from-blue-400 to-violet-400 text-white"
                          : "from-blue-600 to-violet-600 text-white"
                      }`}
                    >
                      {option.icon}
                    </span>
                    <span
                      className={`font-medium ${
                        isDarkMode ? "text-white" : "text-gray-900"
                      }`}
                    >
                      {option.label}
                    </span>
                  </div>
                  <ArrowUpRight
                    className={`w-5 h-5 transform transition-all duration-300 group-hover:translate-x-1 group-hover:-translate-y-1 
                      ${isDarkMode ? "text-gray-400" : "text-gray-500"}`}
                  />
                </div>
                <p
                  className={`text-sm ${
                    isDarkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {option.description}
                </p>
              </button>
            ))}
          </div>

          {/* Completion Animation */}
          {isCompleting && (
            <div
              className={`absolute inset-0 rounded-xl flex items-center justify-center bg-gradient-to-r ${
                isDarkMode
                  ? "from-blue-500/20 to-violet-500/20"
                  : "from-blue-500/10 to-violet-500/10"
              }`}
            >
              <CheckCircle
                className={`w-16 h-16 ${
                  isDarkMode ? "text-blue-400" : "text-blue-500"
                } animate-bounce`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectVisionHero;
