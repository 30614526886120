import React from "react";

import AboutHero from "../components/AboutSections/AboutHero";
import AboutTeam from "../components/AboutSections/AboutTeam";
import AboutOffice from "../components/AboutSections/AboutOffice";
import Values from "../components/AboutSections/Values";
import AboutMission from "../components/AboutSections/AboutMission";

const About = () => {
  return (
    <div>
      <AboutHero />
      <Values />
      <AboutMission />
      <AboutTeam />
      <AboutOffice />
    </div>
  );
};

export default About;
