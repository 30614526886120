import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  Sparkles,
  Clock,
  CheckCircle2,
  ArrowRight,
  Trophy,
  Zap,
  Palette,
  Phone,
} from "lucide-react";

const contactButton = {
  title: "Persönliche Beratung",
  icon: <Phone className="w-5 h-5" />,
  path: "/ContactPage",
};

const SpecialOffer = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isOfferExpired, setIsOfferExpired] = useState(false);

  useEffect(() => {
    // Set end date to December 1st, 2024, 23:59:59 Central European Time
    const endDate = new Date("2024-12-01T23:59:59+01:00").getTime();

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = endDate - now;

      if (difference <= 0) {
        setIsOfferExpired(true);
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    };

    // Initial calculation
    setTimeLeft(calculateTimeLeft());

    // Update every second
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);

      if (
        timeLeft.days === 0 &&
        timeLeft.hours === 0 &&
        timeLeft.minutes === 0 &&
        timeLeft.seconds === 0
      ) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

const AdditionalContactButton = () => {
    const handleClick = (e) => {
      e.preventDefault();
      const contactSection = document.getElementById("Contact");
      if (contactSection) {
        const headerOffset = 100;
        const elementPosition = contactSection.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    return (
      <button
        onClick={handleClick}
        className="w-full sm:w-auto bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-2 border-purple-600 rounded-xl py-4 px-6 font-medium hover:bg-purple-50 dark:hover:bg-gray-700 transition-all duration-300 flex items-center justify-center gap-2 group"
      >
        {contactButton.icon}
        <span>{contactButton.title}</span>
      </button>
    );
  };

  // Early return if offer is expired
  if (isOfferExpired) {
    return (
      <div className="w-full bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800 py-20">
        <div className="max-w-3xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Dieses Angebot ist leider abgelaufen
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Kontaktieren Sie uns für aktuelle Angebote und Konditionen.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/ContactPage"
              className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-xl py-4 px-6 font-medium hover:shadow-lg transition-all duration-300"
            >
              Beratungsgespräch vereinbaren
            </Link>
            <AdditionalContactButton />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative bg-gradient-to-b from-purple-50 to-white dark:from-gray-900 dark:to-gray-800 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-100 dark:bg-purple-900/20 rounded-full blur-3xl" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-indigo-100 dark:bg-indigo-900/20 rounded-full blur-3xl" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-20">
        <div className="text-center mb-12">
          <motion.div
            className="inline-flex items-center gap-2 bg-purple-100 dark:bg-purple-900/50 px-4 py-2 rounded-full text-purple-600 dark:text-purple-300 text-sm font-medium mb-6"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Sparkles className="w-4 h-4" />
            Limitiertes Angebot bis 01.12.2024
          </motion.div>

          <motion.h2
            className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
          >
            Website Design Konzept
            <br />
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">
              für nur 49€
            </span>
          </motion.h2>

          <motion.p
            className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Sichern Sie sich jetzt Ihr professionelles Website-Konzept zum
            einmaligen Einführungspreis
          </motion.p>
        </div>

        {/* Responsive Timer */}
        <motion.div
          className="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-4 mb-12 max-w-2xl mx-auto px-2 sm:px-0"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
        >
          {[
            { label: "Tage", value: timeLeft.days },
            { label: "Stunden", value: timeLeft.hours },
            { label: "Minuten", value: timeLeft.minutes },
            { label: "Sekunden", value: timeLeft.seconds },
          ].map((time, index) => (
            <div
              key={time.label}
              className="flex flex-col items-center bg-white dark:bg-gray-800 rounded-xl shadow-lg p-3 sm:p-4"
            >
              <span className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                {String(time.value).padStart(2, "0")}
              </span>
              <span className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                {time.label}
              </span>
            </div>
          ))}
        </motion.div>

        {/* Main Content */}
        <div className="grid md:grid-cols-2 gap-12 items-center mb-12">
          {/* Left Column - Features */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
          >
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              Das erhalten Sie:
            </h3>
            {[
              {
                icon: Palette,
                title: "Individuelles Design-Konzept",
                description: "Maßgeschneidert für Ihre Marke und Zielgruppe",
              },
              {
                icon: Zap,
                title: "Responsive Layouts",
                description: "Optimiert für alle Geräte und Bildschirmgrößen",
              },
              {
                icon: Trophy,
                title: "Conversion-optimiert",
                description:
                  "Nutzerorientierte Gestaltung für beste Ergebnisse",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="flex items-start gap-4"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5 + index * 0.1 }}
              >
                {React.createElement(feature.icon, {
                  className: "w-6 h-6 text-purple-600 flex-shrink-0",
                })}
                <div>
                  <h4 className="font-semibold text-gray-900 dark:text-white">
                    {feature.title}
                  </h4>
                  <p className="text-gray-600 dark:text-gray-400">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Right Column - Offer Card */}
          <motion.div
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8"
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
          >
            <div className="flex items-center justify-between mb-6">
              <div className="text-sm text-gray-600 dark:text-gray-400 line-through">
                Regulär: 299€
              </div>
              <div className="bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-300 px-3 py-1 rounded-full text-sm font-medium">
                83% Rabatt
              </div>
            </div>

            <div className="text-4xl font-bold text-gray-900 dark:text-white mb-6">
              49€
              <span className="text-base font-normal text-gray-600 dark:text-gray-400 ml-2">
                einmalig
              </span>
            </div>

            <div className="space-y-4 mb-8">
              {[
                "Professionelles Design-Konzept",
                "Mobil-optimierte Layouts",
                "Conversion-optimierte Gestaltung",
                "3 Revisionen inklusive ",
                "Express Lieferung in 7 Tagen",
              ].map((item, index) => (
                <div key={index} className="flex items-center gap-3">
                  <CheckCircle2 className="w-5 h-5 text-green-500" />
                  <span className="text-gray-600 dark:text-gray-300">
                    {item}
                  </span>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <Link
                to="https://buy.stripe.com/bIY7sWf1rbMU4akdQV"
                className="block w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-xl py-4 px-6 font-medium hover:shadow-lg transition-all duration-300 text-center group"
              >
                <span className="flex items-center justify-center gap-2">
                  Jetzt Design sichern
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </Link>

              <AdditionalContactButton />
            </div>

            <p className="text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
              100% Zufriedenheitsgarantie
            </p>
          </motion.div>
        </div>

        {/* Trust Elements */}
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
        >
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Bereits über 500+ zufriedene Kunden vertrauen auf unsere
            Design-Expertise
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default SpecialOffer;
