import React from "react";
import { motion } from "framer-motion";
import { Mail, Phone, MapPin } from "lucide-react";

const KontaktAbschnitt = () => {
  return (
    <div id="Contact" className="bg-gray-100 dark:bg-gray-800 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-start"
          >
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4">
              Kontaktieren Sie uns
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
              Haben Sie eine Frage oder möchten Sie ein Projekt besprechen? Wir
              helfen Ihnen gerne weiter.
            </p>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Mail className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                <a
                  href="mailto:info@beispiel.com"
                  className="text-gray-800 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
                >
                  info@tru-tec.de
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <Phone className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                <a
                  href="tel:+4912345678"
                  className="text-gray-800 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
                >
                  +49 170 7238166
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <MapPin className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                <span className="text-gray-800 dark:text-gray-300 font-medium">
                  Goldener Spiegel 6, 57074 Siegen
                </span>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg"
          >
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Schreiben Sie uns
            </h3>
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Name"
                className="w-full px-4 py-3 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              />
              <input
                type="email"
                placeholder="E-Mail"
                className="w-full px-4 py-3 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              />
              <textarea
                placeholder="Nachricht"
                rows={4}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              ></textarea>
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors duration-300"
              >
                Nachricht senden
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default KontaktAbschnitt;
