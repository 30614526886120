import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ShieldCheck } from "lucide-react";

const AnalyticsBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleAccept = () => {
    setIsBannerVisible(false);
  };

  return (
    <AnimatePresence>
      {isBannerVisible && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-0 left-0 right-0 z-50 px-4 py-3 bg-white dark:bg-gray-900 shadow-lg border-t border-gray-200 dark:border-gray-700"
        >
          <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-4">
            <div className="flex items-center space-x-3">
              <ShieldCheck className="w-8 h-8 sm:w-6 sm:h-6 text-green-500" />
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Keine persönliche Datenerfassung oder Cookies dank Vercel
                Analytics.{" "}
                <a
                  href="/privacypolicy"
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                >
                  Datenschutzerklärung
                </a>
              </p>
            </div>
            <button
              onClick={handleAccept}
              className="w-full sm:w-auto px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm font-medium hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
            >
              Verstanden
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnalyticsBanner;
