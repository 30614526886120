import React from "react";

import Contact from "../components/Contact";
import MobileDevHero from "../components/MobileDevSections/MobileDevHero";
import MobileAppHero from "../components/MobileDevSections/MobileAppHero";

const Mobiledevelopement = () => {
  return (
    <div>
      <MobileDevHero />
      <MobileAppHero />
    </div>
  );
};

export default Mobiledevelopement;
