import React from "react";
import Contact from "../components/Contact";
import ProjectAnalyzerHero from "../components/ProjectAnalyzerHero";

const ProjectAnalyzer = () => {
  return (
    <div>
      <ProjectAnalyzerHero />
      <Contact />
    </div>
  );
};

export default ProjectAnalyzer;
