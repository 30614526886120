import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Imprint from "./pages/Imprint";
import InWork from "./pages/InWork";
import ProjectAnalyzer from "./pages/ProjectAnalyzer";
import Portfolio from "./pages/Portfolio";
import Webdevelopement from "./pages/webdevelopement";
import Softwaredevelopement from "./pages/Softwaredevelopement";
import Mobiledevelopement from "./pages/mobiledevelopement";
import ContactPage from "./pages/ContactPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import CookieBanner from "./components/CookieBanner";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

// ScrollToTop component that will handle the scrolling
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Using 'instant' instead of 'smooth' for page navigation
    });
  }, [location]);

  return null;
}

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <React.Fragment>
        <SpeedInsights />
        <Analytics />
      </React.Fragment>
      <Header />
      <CookieBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/inwork" element={<InWork />} />
        <Route path="/projectanalyzer" element={<ProjectAnalyzer />} />
        <Route path="softwaredevelopement" element={<Softwaredevelopement />} />
        <Route path="/webdevelopement" element={<Webdevelopement />} />
        <Route path="/mobiledevelopement" element={<Mobiledevelopement />} />
        <Route path="/ContactPage" element={<ContactPage />} />
      </Routes>
      <Contact />
      <Footer />
    </Router>
  );
};

export default App;
